import { Button, Input, Text } from "components";
import { useTranslation } from "react-i18next";
import { DefaultMoney } from "./DefaultMoney";
import * as CUSTOM from "../../../../../../utils/helper/custom";

const Account = (props) => {
  const { setIsAddMoney, setPaymentData, paymentData } = props;
  const { t } = useTranslation();
  const moneyAaray = ["+100", "+200", "+300", "+400", "+500"];

  return (
    <>
      <div className="relative flex flex-col w-full">
        {paymentData?.step == 1 ? (
          <>
            <div className="flex flex-col gap-1 items-start justify-start">
              <Text className="text-base text-black-900 font-nunitomedium">
                {t("addMoneyToWallet")}
              </Text>
              <Text className="text-gray-700 text-sm font-nunitoregular w-[80%] xs:w-full">
                {t("enterTheAmountYouWantToAddToYourWallet")}
              </Text>
            </div>
            <div className="flex flex-col items-start justify-start w-full mt-7 mb-[0px]">
              <div className="w-full max-w-[340px] start-padding">
                <Input
                  className="w-full"
                  value={paymentData?.amount}
                  placeholder=""
                  onChange={(e) => {
                    setPaymentData({
                      ...paymentData,
                      amount: CUSTOM.strFormat(e.target.value, "number|max:6"),
                    });
                  }}
                  startAdornment={
                    <Text className="text-black-900 text-sm font-nunitoregular mr-2 rtl:mr-0 rtl:ml-2">
                      {t("sar")}
                    </Text>
                  }
                />
              </div>
              <div className="flex w-full gap-3 flex-wrap -mt-2">
                {moneyAaray.map((items, index) => {
                  return (
                    <DefaultMoney
                      label={items}
                      value={CUSTOM.replaceKeyValue(items, "+", "")}
                      onClick={(e) => {
                        setPaymentData({
                          ...paymentData,
                          amount: e.target.value,
                        });
                      }}
                      checked={items == "+" + paymentData?.amount}
                      name="default"
                      className="w-auto"
                      id={`money-1-${index}`}
                    />
                  );
                })}
              </div>
            </div>
            <div className="flex items-start justify-start xs:justify-center pt-[30px] gap-4 mb-[18px] xs:flex-wrap">
              <Button
                className="flex-1 max-w-[280px] xs:text-sm xs:whitespace-nowrap"
                size="md"
                variant="FillBlack"
                hover={true}
                hoverclass="bg-white-A700"
                onClick={(e) => {
                  setPaymentData({
                    ...paymentData,
                    step: 2,
                  });
                }}
                disabled={!paymentData?.amount || paymentData?.amount==0}
              >
                {t("proceedToAdd")} ({t("sar")} {" "} {paymentData?.amount})
              </Button>
              <Button
                className="w-auto min-w-[92px] xs:text-sm"
                size="md"
                variant="OutlineBlack"
                hover={true}
                hoverclass="bg-black-900"
                onClick={(e) => {
                  setIsAddMoney(false);
                }}
              >
                {t("cancel")}
              </Button>
            </div>
          </>
        ) : null}

        {paymentData?.step == 2 ? (
          <div className="flex flex-col gap-4 items-center justify-start w-full mb-[0px]">
            <div className="flex flex-row md:gap-5 items-center justify-center w-full">
              <div className="flex flex-col gap-1 items-start justify-start flex-1">
                <Text className="text-base text-black-900 font-nunitomedium">
                  {t("addMoneyToWallet")}
                </Text>
                <Text className="text-black-900 text-sm font-nunitoregular w-[80%] xs:w-full">
                  {t("sar")}  {CUSTOM.setPrice(paymentData?.amount)}
                </Text>
              </div>
              <Button
                className="w-auto mx-auto px-3 py-1.5 text-sm"
                size="sm"
                variant="OutlineBlack"
                hover={true}
                hoverclass="bg-black-900"
                onClick={(e)=>{
                  setPaymentData({...paymentData,step:1})
                }}
              >
                {t("change")}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default Account;
