import React, { useEffect, useState } from "react";
import { Button, Img, Input, Text, Radio, CheckboxCustom } from "components";
import { TextField } from "@mui/material";
import { MoneyAdded } from "popups/MoneyAdded";
import { useTranslation } from "react-i18next";
import * as CUSTOM from "../../../../../../utils/helper/custom";
import * as CARDVALIDATION from "../../../../../../utils/helper/payfortValidation";
import globalRequest from "utils/global/globalRequest";
import * as APIS from "../../../../../../utils/helper/Enum";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../../../../redux/reducers/snackbar";
import ApplePayComponent from "pages/ApplePayComponent";
import { getAuth } from "../../../../../../redux/reducers/loginData";
import { changeLoader } from "../../../../../../redux/reducers/loader";

// const Tab = styled.div`
//   /* Add the selected styles when the component is selected */
//   ${({ isSelected }) => isSelected && ` `}
// `;
// const Panal = styled.div`
//   /* Add the selected styles when the component is selected */
//   ${({ isSelected }) =>
//     isSelected &&
//     `
// display: flex;`}
// `;

const Payment = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const { paymentData, setIsAddMoney, setPaymentData, getWalletHistory } =
    props;
  const [cardError, setCardError] = useState({});
  const [cardList, setCardList] = useState([]);
  const [cardTypeError, setCardTypeError] = useState({});
  let [cardData, setCardData] = useState({});
  const [MoneyAddedOpen, setMoneyAddedOpen] = React.useState(false);
  const [showApplePayButton, setShowApplePayButton] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [applePayEmail, setApplePayEmail] = useState("");
  const [applePayError, setApplePayError] = useState("");
  const handleTabClick = (index) => {
    setCardData({});
    setCardError({});
    setActiveTab(index);
  };
  // const handelsetMoneyAddedOpen = () => {
  //   setMoneyAddedOpen(true);
  //   document.body.classList.add("overflow-hidden");
  //   document.getElementById("header-box").classList.add("relative");
  // };

  /**
   * verify cart if valid or not for payment
   */
  const verifyCart = () => {
    const newErrors = {};
    if (!cardData?.token_name) {
      if (cardData.card_number) {
        let resValidation = CARDVALIDATION.validateCard(cardData.card_number);
        if (!resValidation?.validity) {
          newErrors.card_number = t("cardNumberError");
        }
      } else {
        newErrors.card_number = t("cardNumberErrorEmpty");
      }
      if (cardData.expiration_date) {
        if (!CARDVALIDATION.validateExpiryDate(cardData.expiration_date)) {
          newErrors.date = t("cardDateError");
        }
      } else {
        newErrors.date = t("cardDateErrorEmpty");
      }
      if (!cardData.card_holder) {
        newErrors.card_holder = t("cardHolderError");
      } else {
        let cartHolderValidation = CARDVALIDATION.validateHolderName(
          cardData.card_holder
        );
        if (!cartHolderValidation?.validity) {
          newErrors.card_holder = t("cardNumberError");
        }
      }
    }
    if (cardData.cvv) {
      if (!cardData.cvv.match(/^\d{3,4}$/)) {
        newErrors.cvv = t("cvvNumberError");
      }
    } else {
      newErrors.cvv = t("cvvNumberErrorEmpty");
    }
    if (Object.keys(newErrors).length == 0) {
      if (!cardData?.token_name) {
        let expdata = cardData?.expiration_date.split("/");
        cardData.edate = `${expdata[1]}${expdata[0]}`;
        setCardData({
          ...cardData,
          edate: `${expdata[1]}${expdata[0]}`,
        });
      }
      paymentProcess("card");
    }
    setCardError(newErrors);
  };

  const checkCartType = (cardNo) => {
    let res = CUSTOM.getCardType(cardNo);
    setCardTypeError(res);
  };

  /**
   *  Get Card list
   */
  const getCardList = async () => {
    try {
      let response = await globalRequest(
        APIS.USERS.GET_SAVED_CARD_LIST,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setActiveTab(2);
        setCardList(response?.data);
      } else {
        setActiveTab(1);
        setCardList([]);
      }
    } catch (e) {
      setCardList([]);
    }
  };

  /**
   *  Add money in wallet
   */
  const paymentProcess = async (type = "", applePayData, session) => {
    dispatch(changeLoader(true));
    try {
      let cDatas = {
        ...cardData,
        ...paymentData,
        card_security_code: cardData?.token_name ? cardData?.cvv : "",
        payment_method: type ? type : null,
      };
      if (type == "apple_pay") {
        cDatas.applePayData = applePayData;
        cDatas.applePayEmail = applePayEmail;
      }
      let response = await globalRequest(
        APIS.USERS.ADD_MONEY_IN_WALLET,
        "post",
        cDatas,
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        if (response?.data?.otp_link) {
          window.location.href = response?.data?.otp_link;
        } else if (response?.data?.is_card === 1) {
          let cData = {
            ...cardData,
            ...response?.data?.cardData,
            isCard: 1,
          };
          setCardData(cData);
        } else {
          if (type == "apple_pay") {
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
            CUSTOM.sendWalletGaEvent(CUSTOM.setPrice(paymentData?.amount));
            CUSTOM.sendPaymentStatusGaEvent("success", "wallet", auth?.id, auth);
            getWalletHistory();
            setPaymentData({ step: 1 });
            setIsAddMoney(false);
          }
        }
      } else {
        if (type == "apple_pay") {
          session.completePayment(window.ApplePaySession.STATUS_FAILURE);
          CUSTOM.sendPaymentStatusGaEvent("failed", "wallet", auth?.id, auth);
        }
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      if (type == "apple_pay") {
        session.completePayment(window.ApplePaySession.STATUS_FAILURE);
      }
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  const checkApplePayEmail = (email) => {
    if (email) {
      if (!CUSTOM.isValidField(email, "email")) {
        setApplePayError(t("please_enter_valid_email"));
      } else {
        setApplePayError("");
      }
    } else {
      setApplePayError(t("email_name_required"));
    }
  };

  useEffect(() => {
    getCardList();
  }, []);

  useEffect(() => {
    if (cardData?.isCard == 1) {
      let form = document.getElementById("aps_payment_form");
      if (form) {
        form.submit();
      }
    }
  }, [cardData]);

  useEffect(() => {
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      setShowApplePayButton(true);
    }
  }, []);

  return (
    <>
      <form
        method="post"
        action={`${
          process.env.REACT_APP_PAYFORT_MODE == "TEST"
            ? process.env.REACT_APP_PAYFORT_DEVLOPMENT
            : process.env.REACT_APP_PAYFORT_PRODUCTION
        }/FortAPI/paymentPage`}
        id="aps_payment_form"
        name="aps_payment_form"
        className="hidden"
      >
        <span
          dangerouslySetInnerHTML={{
            __html: CUSTOM.paymentFormHtml(cardData),
          }}
        ></span>
      </form>
      <div className="relative flex flex-col w-full">
        <div className="flex flex-row xs:flex-col xs:items-start gap-4 items-center justify-between w-full">
          <div className="flex flex-col gap-1 items-start justify-start flex-1">
            <Text className="text-base text-black-900 font-nunitomedium">
              {t("payment")}
            </Text>
            <div className="flex gap-1 flex-row items-center">
              <Text className="text-black-900 text-sm font-nunitoregular">
                {t("selectAnOptionToPay")}
              </Text>
            </div>
          </div>
        </div>

        {paymentData?.step == 2 ? (
          <>
            <div className="flex flex-col items-center justify-between w-full mt-7">
              {cardList.map((items, index) => {
                let tabind = index + 2;
                return (
                  <div
                    className="common-pointer flex flex-row gap-3 items-start justify-start w-full pb-3.5 border-b border-solid border-gray-300"
                    isSelected={activeTab === tabind}
                    // onClick={() => handleTabClick(tabind)}
                  >
                    <Radio
                      name="paymentMode"
                      checked={activeTab === tabind}
                      onChange={(e) => {
                        handleTabClick(tabind);
                        setCardData({
                          ...cardData,
                          token_name: items?.cardToken,
                        });
                      }}
                    />
                    <div className="flex flex-col items-start gap-1 justify-start flex-1 mt-0.5">
                      <Text className="text-base text-black-900 font-nunitoregular">
                        {items?.type}
                      </Text>
                      <div className="flex flex-row gap-2 items-center justify-start flex-1">
                        <Text className="text-gray-700 text-sm font-nunitoregular">
                          {items?.cardNumber}
                        </Text>
                        <Text className="text-black-900 text-sm font-nunitomedium">
                          <Img
                            className="h-6"
                            src={CUSTOM.cardTypeLogo(items?.type)}
                            alt="signal"
                          />
                        </Text>
                      </div>

                      {activeTab == tabind ? (
                        <div className="flex flex-col items-start justify-start mt-5 mb-[18px] xs:-ml-7">
                          <div className="max-w-[280px] w-full">
                            <Input
                              required
                              label={t("cvv")}
                              type="password"
                              value={cardData?.cvv}
                              error={cardError?.cvv}
                              onChange={(e) => {
                                setCardData({
                                  ...cardData,
                                  token_name: items?.cardToken,
                                  cvv: CUSTOM.strFormat(
                                    e.target.value,
                                    "max:4|number"
                                  ),
                                });
                                setCardError({ ...cardError, cvv: "" });
                              }}
                            />
                          </div>
                          <div className="w-full flex gap-4">
                            <Button
                              className="flex-1 mx-auto xs:w-full max-w-[242px]"
                              size="lg"
                              variant="FillBlack"
                              hover={true}
                              hoverclass="bg-white-A700"
                              onClick={(e) => {
                                verifyCart();
                              }}
                            >
                              {t("payNow")} ({t("sar")}{" "}
                              {CUSTOM.setPrice(paymentData?.amount)})
                            </Button>
                            <Button
                              className="w-auto min-w-[92px]"
                              size="md"
                              variant="OutlineBlack"
                              hover={true}
                              hoverclass="bg-black-900"
                            >
                              {t("cancel")}
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}

              <div
                className="common-pointer flex flex-row gap-3 items-start justify-start w-full pt-5 pb-5 "
                isSelected={activeTab === 1}
              >
                <Radio
                  className="flex"
                  name="paymentMode"
                  checked={activeTab === 1}
                  onChange={() => handleTabClick(1)}
                />
                <div className="flex flex-col items-start gap-1 justify-start flex-1 -mt-1">
                  <Text className="text-base text-black-900 font-nunitoregular">
                    {t("creditDebitCard")}
                  </Text>

                  {activeTab == 1 ? (
                    <>
                      <div className="grid grid-cols-2 mt-3 xs:grid-cols-1 gap-x-5 w-full ">
                        <Input
                          required
                          label={t("cardNumber")}
                          value={cardData?.card_number}
                          error={cardError?.card_number}
                          onChange={(e) => {
                            checkCartType(e.target.value);
                            setCardData({
                              ...cardData,
                              card_number: CUSTOM.strFormat(
                                e.target.value,
                                "number|max:16"
                              ),
                            });
                            setCardError({ ...cardError, card_number: "" });
                          }}
                          endAdornment={
                            cardTypeError?.image ? (
                              <img
                                src={cardTypeError?.image}
                                className="h-5 rtl:ml-3 card-type-img-in-card-no"
                                alt="Card Type Error"
                              />
                            ) : null
                          }
                        />

                        <Input
                          required
                          label={t("cardHolderName")}
                          value={cardData?.card_holder}
                          error={cardError?.card_holder}
                          onChange={(e) => {
                            setCardData({
                              ...cardData,
                              card_holder: CUSTOM.strFormat(
                                e.target.value,
                                "text|max:50"
                              ),
                            });
                            setCardError({ ...cardError, card_holder: "" });
                          }}
                        />
                      </div>
                      <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-5 w-full">
                        <Input
                          required
                          label={t("expiryDate(MM/YY)")}
                          value={cardData?.expiration_date}
                          error={cardError?.date}
                          onChange={(e) => {
                            setCardData({
                              ...cardData,
                              expiration_date: CUSTOM.strFormat(
                                e.target.value,
                                "max:15|expiry_date"
                              ),
                            });
                            setCardError({ ...cardError, date: "" });
                          }}
                        />
                        <Input
                          required
                          label={t("cvv")}
                          type="password"
                          value={cardData?.cvv}
                          error={cardError?.cvv}
                          onChange={(e) => {
                            setCardData({
                              ...cardData,
                              cvv: CUSTOM.strFormat(
                                e.target.value,
                                "max:4|number"
                              ),
                            });
                            setCardError({ ...cardError, cvv: "" });
                          }}
                        />
                      </div>
                      <div className="flex items-center justify-start w-full -mt-3">
                        <CheckboxCustom
                          checked={cardData?.remember_me == "YES"}
                          onChange={(e) => {
                            setCardData({
                              ...cardData,
                              remember_me: e.target.checked ? "YES" : "NO",
                            });
                          }}
                        />
                        <Text className="text-black-900 text-base font-nunitoregular -ml-4 rtl:ml-0 rtl:-mr-4 -mt-1">
                          {t("saveCardDetailsForFuturePurpose")}
                        </Text>
                      </div>
                      <div className="w-full max-w-[242px] mt-5">
                        <Button
                          className="flex-1 mx-auto"
                          size="lg"
                          variant="FillBlack"
                          hover={true}
                          hoverclass="bg-white-A700"
                          onClick={() => {
                            //handelsetMoneyAddedOpen();
                            verifyCart();
                          }}
                        >
                          {t("payNow")} (SAR{" "}
                          {CUSTOM.setPrice(paymentData?.amount)})
                        </Button>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              {!!showApplePayButton && (
                <div
                  className="common-pointer flex flex-row gap-3 items-start justify-start w-full pt-5 pb-5 border-b "
                  isSelected={activeTab === 2}
                >
                  <Radio
                    className="flex"
                    name="paymentMode"
                    checked={activeTab === 2}
                    onChange={() => handleTabClick(2)}
                  />
                  <div className="flex flex-col items-start gap-1 justify-start flex-1 -mt-1">
                    <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-0 w-full mb-0">
                      {/* <img src="/images/tabby-logo.svg" alt="tabby-logo" style={{ maxWidth: '45%', maxHeight: '40%' }}/> */}
                      <Text className="text-base text-black-900 font-nunitoregular">
                        {"Pay with Apple Pay"}
                      </Text>
                      {/* <InfoIcon onClick={handleInfoIconClick} /> */}
                    </div>
                    {activeTab == 2 ? (
                      <div className="hidden111 max-w-[580px] w-full flex flex-col items-start justify-start mt-6 mb-[18px]">
                        {!auth?.email ? (
                          <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-5 w-full mb-2">
                            <TextField
                              required
                              label={t("Email")}
                              type="text"
                              value={applePayEmail}
                              error={!!applePayError}
                              helperText={applePayError || " "}
                              onChange={(e) => {
                                let email = CUSTOM.strFormat(
                                  e.target.value,
                                  "max:50|email"
                                );
                                setApplePayEmail(email);
                                checkApplePayEmail(email);
                              }}
                            />
                          </div>
                        ) : null}
                        <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-5 w-full mb-2">
                          <ApplePayComponent
                            applePayData={{
                              amount: CUSTOM.setPrice(paymentData?.amount),
                            }}
                            payViaOtherSource={paymentProcess}
                            isApplePayEnabled={
                              auth?.email || (applePayEmail && !applePayError)
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className="bg-red-50_01 flex xs:flex-col gap-4 flex-row items-center justify-between py-[22px] px-[30px] xs:p-5 absolute bottom-[-88px] xs:bottom-[-148px] left-[-24px] xs:-left-4 payment-secure-box">
              <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
                <Img
                  className="h-9 w-9"
                  src="/images/img_shielddone.svg"
                  alt="shielddone"
                />
                <div className="flex flex-col gap-1 items-start justify-start">
                  <Text className="text-base text-black-900 font-nunitomedium">
                    {t("secureCardPayment")}
                  </Text>
                  <Text className="text-black-900 text-xs font-nunitoregular">
                    {t("100SecurePaymentsPoweredByRabbitHole")}
                  </Text>
                </div>
              </div>
              <div className="gap-2 grid grid-cols-4 w-auto">
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="h-8 w-[44px] bg-white-A700 rounded-md">
                    <Img
                      className="h-full w-full object-contain"
                      src="/images/img_file_white_a700.svg"
                      alt="file"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="h-8 w-[44px] bg-white-A700 rounded-md">
                    <Img
                      className="h-full w-full object-contain"
                      src="/images/img_023mastercard1.png"
                      alt="023mastercardOne"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="h-8 w-[44px] bg-white-A700 rounded-md">
                    <Img
                      className="h-full w-full object-contain"
                      src="/images/img_007visa.png"
                      alt="007visa"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-start w-full">
                  <div className="h-8 w-[44px] bg-white-A700 rounded-md">
                    <Img
                      className="h-full w-full object-contain"
                      src="/images/img_1200pxrupaysvg.png"
                      alt="1200pxrupaysvg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </div>
      {MoneyAddedOpen === true ? (
        <MoneyAdded closepopup={setMoneyAddedOpen} />
      ) : null}
    </>
  );
};

export default Payment;
