import { Img, Text } from "components";
// Importing localization strings
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setSnackbar } from "redux/reducers/snackbar";
import * as CUSTOM from "../../utils/helper/custom";

const Share = ({ closepopup, images }) => {
  const { t } = useTranslation();
  let dispatch = useDispatch();

  const closepopupout = () => {
    closepopup(false);
    document.body.classList.remove("overflow-hidden");
    document.getElementById("header-box").classList.remove("relative");
  };
  
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none m-auto w-fit h-fit xs:px-4">
        <div className="relative w-auto my-6 mx-auto max-w-[400px] min-w-[400px] xs:min-w-full xs:max-w-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white-A700 outline-none focus:outline-none overflow-hidden">
            {/*header*/}
            <div className="flex flex-col items-center justify-start w-auto">
              <div className="flex flex-col items-center justify-start w-auto">
                <div className="flex flex-row items-start justify-end w-auto pt-4 pr-4 rtl:pr-0 rtl:pl-4 absolute right-0 rtl:right-auto rtl:left-0">
                  <Img
                    src="/images/modal-close.svg"
                    className="common-pointer h-6 w-6"
                    alt="close"
                    onClick={() => closepopupout()}
                  />
                </div>
              </div>
            </div>
            {/*body*/}
            <div className="bg-white-A700 flex flex-col items-center justify-start px-[30px] pt-3 pb-8 rounded-lg w-full">
              <div className="flex flex-col justify-start w-full relative z-[5]">
                <div className="relative flex flex-row items-center justify-center w-full">
                  <Img
                    className="h-auto w-[122px] cursor-pointer"
                    src="/images/share-icon.svg"
                    alt="icon"
                  />
                </div>
                <div className="flex flex-row items-center justify-start w-full mt-3">
                  <Text
                    className="leading-[32.00px] text-2xl md:text-[22px] text-black-900 sm:text-xl w-full text-center"
                    size="txtnunitoSemiBold24"
                  >
                    {t("shareThisImages")}
                  </Text>
                </div>
                <div className="flex flex-row flex-wrap gap-2 items-center mt-2 justify-start w-full">
                  <div
                    className="w-[47%] text-[14px] text-black_900 font-normal flex flex-row gap-x-5 border border-gray_300 p-2 cursor-pointer"
                    onClick={async (e) => {
                      try {
                        await navigator.clipboard.writeText(images.join());
                        dispatch(
                          setSnackbar({
                            snackbarOpen: true,
                            snackbarMessage: "Link copied! successfully",
                            snackbarState: "success",
                          })
                        );
                      } catch (err) {
                        setSnackbar({
                          snackbarOpen: true,
                          snackbarMessage: err?.message,
                          snackbarState: "error",
                        });
                      }
                    }}
                  >
                    <img
                      src="/images/copy.svg"
                      alt="social icons"
                      className="w-6 h-6 "
                    />
                    Copy Link
                  </div>
                  <div className="w-[47%] text-[14px] text-black_900 font-normal flex flex-row gap-x-5 border border-gray_300 p-2 cursor-pointer"
                  onClick={(e)=>{
                    CUSTOM.shareOnSocial('email',images);
                  }}
                  >
                    <img
                      src="/images/email.svg"
                      alt="social icons"
                      className="w-6 h-6 "
                    />
                    Email
                  </div>
                  {/* <div className="w-[47%] text-[14px] text-black_900 font-normal flex flex-row gap-x-5 border border-gray_300 p-2">
                    <img
                      src="/images/messages.svg"
                      alt="social icons"
                      className="w-6 h-6 "
                    />
                    Message
                  </div>
                  <div className="w-[47%] text-[14px] text-black_900 font-normal flex flex-row gap-x-5 border border-gray_300 p-2">
                    <img
                      src="/images/whatsapp.svg"
                      alt="social icons"
                      className="w-6 h-6 "
                    />
                    WhatsApp
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-75 fixed inset-0 z-40 bg-black-900"
        onClick={() => closepopupout()}
      ></div>
    </>
  );
};
export { Share };
