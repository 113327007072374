import React from "react";
import { Button, Img, Line, Text } from "components";
import { useTranslation } from "react-i18next";
import * as CUSTOM from "../../../../utils/helper/custom";

const RecipientDetail = ({details}) => {
  const { t } = useTranslation(); 
  return (
    <>
      <div className="border flex flex-col items-center justify-start max-w-[331px] min-w-[331px] w-full px-8 py-5">
        <div className="relative w-full flex flex-col gap-5">
          <div className="flex flex-col items-center justify-center">
            <Img
              className="h-auto w-[122px]"
              src="/images/money-added-icon.svg"
              alt="checkmark"
            />
            <Text
              className="mt-3 text-xl md:text-[22px] text-black-900 text-center sm:text-lg"
              size="txtnunitoSemiBold24"
            >
              {t("Recipient Details")}
            </Text>
          </div>

          <div className="relative flex flex-row gap-4 items-start justify-start w-full z-[2]">
            <Button className="bg-red-100 flex h-8 items-center justify-center p-1 w-8 mt-2 relative z-50">
              <Img
                className="h-5 w-5"
                src="/images/person.svg"
                alt="location_Two"
              />
            </Button>
            <div className="flex flex-col gap-1 items-start justify-start w-full flex-1">
              <Text className="font-nunitomedium text-base text-black-900">
                 {details?.giftDetails?.recipient_name}
              </Text>
              <Text className="font-nunitoregular text-black-900 text-sm break-all">
                {CUSTOM.replaceKeyValue(details?.giftDetails?.mobile_no,'-'," ")} |  {details?.giftDetails?.email} | {details?.giftDetails?.occasion_name}
              </Text>
              <Text className="text-gray-700 text-xs font-nunitoregular">
                Recipient Details
              </Text>
            </div>
          </div>
          <div className="relative flex flex-row gap-4 items-start justify-start w-fulll z-[1]">
            <Line className="absolute -top-full h-full left-[15px] rtl:left-auto rtl:right-[15px] w-px ltr:border-l rtl:border-r border-dashed border-gray-900 z-10" />
            <Button className="bg-red-100 flex h-8 items-center justify-center p-1 w-8 mt-2 relative z-50">
              <Img
                className="h-5 w-5"
                src="/images/location_on.svg"
                alt="location_Two"
              />
            </Button>
            <div className="flex flex-col gap-1 items-start justify-start w-full flex-1">
              <Text className="font-nunitomedium text-base text-black-900 w-full md:max-w-[415px]">
                {details?.addressDetails?.address}
              </Text>
              <Text className="text-gray-700 text-xs font-nunitoregular">
                Delivery Address
              </Text>
            </div>
          </div>
          <div className="relative flex flex-row gap-4 items-start justify-start w-full z-[0]">
            <Line className="absolute -top-full h-full left-[15px] rtl:left-auto rtl:right-[15px] w-px ltr:border-l rtl:border-r border-dashed border-gray-900 z-10" />
            <Button className="bg-red-100 flex h-8 items-center justify-center p-1 w-8 relative z-50">
              <Img
                className="h-5 w-5"
                src="/images/event_note.svg"
                alt="calendar"
              />
            </Button>
            <div className="flex flex-col gap-1 items-start justify-start flex-1">
              <Text className="font-nunitomedium text-base text-black-900">
                {CUSTOM.changeDateFormat(details?.deliveryDate,'dd/MM/yyyy')}, {CUSTOM.formatTimeRange(details?.timeSlotDetails?.openTime,details?.timeSlotDetails?.closeTime)} 
              </Text>
              <Text className="font-nunitoregular text-gray-700 text-xs">
                Scheduled Date & Time
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecipientDetail;
