import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Img, Text } from "components";
import { AddToCart } from "popups/AddToCart";
import { RepeatOrder } from "popups/RepeatOrder";
import { useTranslation } from "react-i18next";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { setSnackbar } from "../../redux/reducers/snackbar";
import * as APIS from "../../utils/helper/Enum";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import styled from "styled-components";
import { changeTrigger } from "redux/reducers/trigger";
import * as CUSTOM from "../../utils/helper/custom";
// const useStyles = makeStyles((theme) => ({
//   customTooltip: {
//     backgroundColor: "white", // Change the background color here
//     color: "black", // Change the font color here
//     fontSize: "16px", // You can adjust the font size here if needed
//     boxShadow: "10px 14px 16px rgba(0, 0, 0, 0.1)",
//     padding: "20px",
//   },
// }));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    margin: 0,
    marginLeft: "-20px",
    padding: 0,
    boxShadow: "0 0 25px -5px rgb(0 0 0 / 0.1), 0 0 10px -6px rgb(0 0 0 / 0.1)",
    ".MuiTooltip-arrow": {
      color: "#FFFFFF", // Arrow color
      width: "28px", // Arrow width
      height: "16px", // Arrow height
      top: "-7px", // Position where arrow is pointing
      left: "12px!important", // Position where arrow is pointing
    },
  },
}));

const ProductCartCardBox = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [AddToCartOpen, setAddToCartOpen] = React.useState(false);
  const [uomData, setUomData] = React.useState({});
  const [main_product_id, setPpoductId] = React.useState({});
  const [cartData, setCartData] = React.useState([]);
  const [RepeatOrderOpen, setRepeatOrderOpen] = React.useState(false);
  const [openTooltip, setOpenTooltip] = React.useState({
    status: false,
    id: 0,
  });
  const [incrementQty, setIncrementQty] = React.useState({
    id: 0,
    qty: 0,
    product_id: 0,
    uomData: {},
  });

  const handelAddToCart = () => {
    setAddToCartOpen(true);
    document.body.classList.add("overflow-hidden");
    document.getElementById("header-box").classList.add("relative");
  };

  const CheckUomExistInCart = () => {
    if (cartData.length > 0 && Object.keys(uomData).length > 0) {
      let data = cartData.filter(
        (items) => items?.productId == main_product_id
      );
      if (data.length > 0) {
        let quantityData = { ...data[data.length - 1], quantity: data.reduce((total, item) => {
          return total + item.quantity;
        }, 0)};
        return quantityData;
      }
    }
  };

  /**
  * get modifiers
  */
  const getModifiers = async (id) => {
    try {
      let response = await globalRequest(
        `${APIS?.PRODUCTS?.GET_MODIFIER_BY_UOM_ID}/${id}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        return {
          status: "success",
          data: response?.data
        }
      } else {
        return {
          status: "success",
          data: [],
        }
      }
    } catch (e) {
      return {
        status: "error",
        message: e?.message,
      }
    }
  };

  /**
  * get product uoms
  */
  const getProductUoms = async (id) => {
    try {
      let response = await globalRequest(
        `${APIS?.PRODUCTS?.GET_UOMS_BY_PRODUCT_ID}/${id}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        return {
          status: "success",
          data: response?.data
        };
      } else {
        return {
          status: "success",
          data: [],
        };
      }
    } catch (e) {
      return {
        status: "error",
        message: e?.message,
      }
    }
  };

  /**
   * Decreament cart item quantity
   */
  const decrementCount = async (id, qtyCount, type = "subtract") => {
    //getCartItemID
    try {
      dispatch(changeLoader(true));
      let inputData = {
        cart_item_id: id,
        qty: type == "subtract" ? Number(qtyCount) - 1 : Number(qtyCount) + 1,
        gaData: CUSTOM.getGACookieValues()
      };
      let response = await globalRequest(
        APIS?.CART?.UPDATE_QTY,
        "put",
        inputData,
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        dispatch(changeTrigger("fetchCartCount"));
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "success",
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  const changeQuy = (id, type = "subtract") => {
    if (cartData.length > 0 && Object.keys(uomData).length > 0) {
      let data = cartData.filter((items) => items?.productId == id);
      if (type == "add") {
        let lastids = data[data.length - 1];
        setIncrementQty({
          id: lastids?.id,
          qty: Number(lastids?.quantity),
          product_id: lastids?.productId,
          uomData: lastids,
        });
      } else {
        if (data.length > 1) {
          setOpenTooltip({ status: true, id: id });
        } else {
          decrementCount(data?.[0]?.id, data?.[0]?.quantity, type);
          setOpenTooltip({ status: false, id: 0 });
        }
      }
    }
  };

  /**
   *  check product data
   * */
  const checkProductData = async (id) => {
    let uomData = await getProductUoms(main_product_id);
    if (uomData?.status == "error") {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: uomData?.message,
          snackbarState: "error",
        })
      );
      return "error";
    }
    if (uomData?.data?.length <= 1) {
      let modifierData = await getModifiers(uomData?.data?.[0]?.id);
      if (modifierData?.status == "error") {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: modifierData?.message,
            snackbarState: "error",
          })
        );
        return "error";
      }
      if (modifierData?.data?.length == 0) {
        if (cartData.length > 0 && Object.keys(uomData).length > 0) {
          let data = cartData.filter((items) => items?.productId == id);
          let lastids = data[data.length - 1];
          decrementCount(lastids?.id,  Number(lastids?.quantity), "add");
          return "incremented";
        }
      }
    }
    return "open_popup";
  }

  useEffect(() => {
    if (openTooltip?.status) {
      setTimeout(() => {
        setOpenTooltip({
          status: false,
          id: 0,
        });
      }, 2500);
    }
  }, [openTooltip?.status]);

  useEffect(() => {
    if (typeof props?.uomData !== "undefined") {
      setUomData(props?.uomData);
    }
  }, [props]);

  useEffect(() => {
    if (typeof props?.productId !== "undefined") {
      setPpoductId(props?.productId);
    }
  }, [props]);

  useEffect(() => {
    if (typeof props?.cartData !== "undefined") {
      setCartData(props?.cartData);
    }
  }, [props]);

  useEffect(() => {
    if (RepeatOrderOpen == "incQty") {
      setRepeatOrderOpen(false);
      decrementCount(incrementQty?.id, incrementQty?.qty, "add");
    }
    if (RepeatOrderOpen == "addNewProduct") {
      setRepeatOrderOpen(false);
      setAddToCartOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RepeatOrderOpen]);

  return (
    <>
      <div className={props.className}>
        <div className="flex flex-col items-start rtl:items-end justify-start w-full">
          <div
            className="h-[220px] relative w-full cursor-pointer"
            onClick={() => navigate(`/productdetail/${props?.slug}`)}
          >
            <Img
              className="absolute h-[220px] inset-[0] justify-center m-auto object-cover rounded-lg w-full"
              alt="rectangle17564"
              src={props?.productimage}
            />

            {Number(props?.isBestSeller) == 1 ? (
              <div className="bg-red-50_01 font-nunitoregular min-w-[74px] py-1 px-2 rounded text-center text-gray-900_01 text-xs absolute mt-2 ml-2 rtl:ml-0 rtl:mr-2 top-0 ltr:left-0 rtl:right-0">
                {t("bestseller")}
              </div>
            ) : (
              ""
            )}

            {Number(props?.isRecommended) == 1 ? (
              <div className="bg-red-50_01 font-nunitoregular min-w-[74px] py-1 px-2 rounded text-center text-gray-900_01 text-xs absolute mt-2 ml-2 rtl:ml-0 rtl:mr-2 top-0 ltr:left-0 rtl:right-0">
                {t("recommended")}
              </div>
            ) : (
              ""
            )}

            {props?.productSortingBox && (
              <div className="bg-red-50_01 font-nunitoregular min-w-[74px] py-1 px-2 rounded text-center text-gray-900_01 text-xs absolute mt-2 ml-2 rtl:ml-0 rtl:mr-2 top-0 ltr:left-0 rtl:right-0">
                {props?.productsorting}
              </div>
            )}
            {props?.productRatingBox && (
              <div className="bg-red-50_01 flex items-center justify-center min-w-[67px] px-1 py-[3px] rounded absolute mb-2 ml-2 rtl:ml-0 rtl:mr-2 bottom-0 ltr:left-0 rtl:right-0">
                <Img
                  className="h-4 mb-px mr-1"
                  src="/images/img_star.svg"
                  alt="star"
                />
                <div className="font-nunitoregular text-gray-900_01 text-left text-xs">
                  {props?.productrating}
                </div>
              </div>
            )}
          </div>
          <Text
            className="mt-3 text-base text-black-900 cursor-pointer w-full rtl:text-right line-clamp-1"
            size="txtnunitoMedium16"
            onClick={() => navigate(`/productdetail/${props?.slug}`)}
          >
            {props?.productName}
          </Text>
          <div
            className="flex flex-row gap-2 items-center justify-start mt-[13px] w-full cursor-pointer"
            onClick={() => navigate("/productdetail")}
          >
            <Text
              className="text-base text-black-900"
              size="txtnunitoMedium16"
            >
              {props?.discountedPrice}
            </Text>
            <Text
              className="line-through text-gray-700 text-sm"
              size="txtnunitoRegular14"
            >
              {props?.originalPrice}
            </Text>
          </div>
          {!CheckUomExistInCart() && (
            <div className="flex flex-col items-center justify-center mt-5 rounded w-full">
              <Button
                className="border border-black-900 border-solid text-black-900 flex flex-row gap-2 items-center justify-center pl-4 sm:pr-5 pr-6 py-3 rounded w-full"
                hover={true}
                hoverclass="bg-black-900"
                onClick={() => {
                  handelAddToCart();
                }}
              >
                <Img
                  className="h-5 w-5 btn-icon"
                  src="/images/img_mdicart.svg"
                  alt="mdicart"
                />
                <Text
                  className="text-base text-center w-auto"
                  size="txtnunitoMedium16"
                >
                  {t("addToCart")}
                </Text>
              </Button>
            </div>
          )}

          {CheckUomExistInCart() && (
            <div className="border border-black-900 border-solid bg-black-900 flex flex-col items-center justify-center mt-5 rounded w-full">
              <div className="flex flex-row gap-2 items-center justify-center pl-4 sm:pr-5 pr-6 py-3 rounded w-full">
                <HtmlTooltip
                  placement="bottom-start"
                  open={
                    openTooltip?.status && openTooltip?.id == main_product_id
                  }
                  arrow
                  title={
                    <>
                      <div className="py-4 px-5 w-full">
                        <Text className="leading-5 text-black-900 text-sm w-full font-nunitomedium">
                        {t("multiple_customization")}
                        </Text>
                      </div>
                    </>
                  }
                >
                  <div>
                    <Img
                      className="h-5 w-5 cursor-pointer"
                      src="/images/minus-white.svg"
                      alt="minus"
                      onClick={() => {
                        changeQuy(main_product_id, "subtract");
                      }}
                    />
                  </div>
                </HtmlTooltip>

                {/* <Tooltip
                  classes={{ tooltip: classes.customTooltip }}
                  open={openTooltip}
                  onClose={handleTooltipClose}
                  title={t("multiple_customization")}
                  arrow
                  placement="bottom"
                ></Tooltip> */}
                <Text
                  className="text-base text-white-A700 text-center w-[100px]"
                  size="txtnunitoMedium16"
                >
                  {CheckUomExistInCart()?.quantity}
                </Text>
                <Img
                  className="h-5 w-5 cursor-pointer"
                  src="/images/add-white.svg"
                  alt="add"
                  onClick={async () => {
                    let data = await checkProductData(main_product_id);
                    if (data != "open_popup") return
                    changeQuy(main_product_id, "add");
                    setRepeatOrderOpen(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {AddToCartOpen === true ? (
        <AddToCart closepopup={setAddToCartOpen} productId={props?.productId} />
      ) : null}

      {RepeatOrderOpen === true ? (
        <RepeatOrder
          closepopup={setRepeatOrderOpen}
          cartIds={incrementQty}
          cartData={cartData}
        />
      ) : null}
    </>
  );
};

ProductCartCardBox.defaultProps = {
  productimage: "",
  productSortingBox: "",
  productsorting: "",
  productRatingBox: "",
  productrating: "",
  productName: "",
  discountedPrice: "",
  originalPrice: "",
  buttonAddToCart: "",
  buttonAddRemoveProduct: "",
};

export default ProductCartCardBox;
