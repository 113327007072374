import { Img, Text } from "components";
import { useTranslation } from "react-i18next";
import * as CUSTOM from "../../../../utils/helper/custom";
import { useEffect, useState } from "react";

const TotalBillSection = ({ orderData }) => {
  const { t } = useTranslation();
  let refundData = orderData?.orderData?.refundRequest;
  let refundWalletHistory = orderData?.refundWalletHistory;
  const [totalWalletRefund, setTotalWalletRefund] = useState(0);
  const [transactionData, setTransactionData] = useState({});

  useEffect(() => {
    let totalWalletRefund = 0;
    refundWalletHistory.map((items) => {
      totalWalletRefund += items?.amount;
    });
    setTotalWalletRefund(totalWalletRefund);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  useEffect(() => {
    if (orderData?.transactionData?.id) {
      setTransactionData(CUSTOM.getPaymentMethod(orderData?.transactionData));
    }
  }, [orderData])

  return (
    <>
      <div className="flex flex-row xs:flex-col gap-4 items-start justify-start w-full">
        <div className="flex flex-col gap-1 items-start justify-start w-full">
          <Text className="font-nunitoregular text-sm text-gray-700 mb-2">
            {/* {orderData?.transactionData &&
            orderData?.transactionData?.method != "card"
              ? t("paidviaWallet")
              : ""}
            {orderData?.transactionData?.transactionDetails?.cardAmount > 0 &&
            orderData?.transactionData?.transactionDetails?.walletAmount > 0
              ? t("paidviaBankAndWallet")
              : ""}
            {orderData?.transactionData?.transactionDetails?.cardAmount > 0 &&
            orderData?.transactionData?.transactionDetails?.walletAmount == 0
              ? t("paidviaBank")
              : ""} */}
            {t(transactionData?.type)}
          </Text>

          {
            // orderData?.transactionData?.method != "wallet" &&
            // orderData?.transactionData?.transactionDetails?.cardAmount > 0
            transactionData?.isCardUsed ? (
              <div className="p-2 bg-gray-50_02 flex items-center gap-4 p-3 min-w-[320px] xs:min-w-full xs:flex-wrap">
                <Img
                  className="object-contain  bg-white rounded px-2 py-3"
                  src={CUSTOM.cardTypeLogo(
                    orderData?.transactionData?.transactionDetails
                      ?.payment_option
                  )}
                  alt="img_logosvisa"
                />
                <div className="relative flex flex-col gap-1">
                  <Text className="font-nunitoregular text-sm text-black-900">
                    {t("paidByCardEndingIn")}{" "}
                    {orderData?.transactionData?.cardNumber}
                  </Text>
                  <Text className="font-nunitoregular text-[12px] text-gray-700">
                    {t("transactionID")} : #
                    {orderData?.transactionData?.transactionId}
                  </Text>
                </div>
              </div>
            ) : null
          }
          {transactionData?.isTabbyUsed ? (
            <div className="p-2 bg-gray-50_02 flex items-center gap-4 p-3 min-w-[320px] xs:min-w-full xs:flex-wrap">
              <Img
                className="object-contain  bg-white rounded px-2 py-3"
                src="/images/tabby/tabby-badge.png"
                alt="img_logosvisa"
              />
              <div className="relative flex flex-col gap-1">
                {/* <Text className="font-nunitoregular text-sm text-black-900">
                  {t("paidByCardEndingIn")}{" "}
                  {orderData?.transactionData?.cardNumber}
                </Text> */}
                <Text className="font-nunitoregular text-[12px] text-gray-700">
                  {t("transactionID")} : #
                  {orderData?.transactionData?.transactionId}
                </Text>
              </div>
            </div>
          ) : null}
          {transactionData?.isApplePayUsed ? (
            <div className="p-2 bg-gray-50_02 flex items-center gap-4 p-3 min-w-[320px] xs:min-w-full xs:flex-wrap">
              <Img
                className="object-contain  bg-white rounded px-2 py-3"
                src="/images/apple-pay/Apple_Pay_Mark.svg"
                alt="img_logosvisa"
                style={{ width: '64px', height: 'auto' }}
              />
              <div className="relative flex flex-col gap-1">
                <Text className="font-nunitoregular text-sm text-black-900">
                  {t("paidByCardStartingWith")}{" "}
                  {orderData?.transactionData?.cardNumber}
                </Text>
                <Text className="font-nunitoregular text-[12px] text-gray-700">
                  {t("transactionID")} : #
                  {orderData?.transactionData?.transactionId}
                </Text>
              </div>
            </div>
          ) : null}
        </div>
        <div className="flex flex-col gap-1 items-end justify-start w-full">
          <div className="flex flex-row relative justify-end w-full gap-1 mb-3">
            <Text className="text-base text-black-900 font-nunitoregular font-normal">
              {t("billTotal")} :
            </Text>
            <Text className="text-black-900 text-base font-nunitomedium font-normal min-w-[130px] text-right">
              {t("sar")}{" "}
              {CUSTOM.setPrice(
                orderData?.orderData?.priceDetails?.total_amount
              )}
            </Text>
          </div>
          {transactionData?.isWalletUsed ? (
            <>
              <div className="flex flex-row relative justify-end w-full gap-1 mb-2">
                <Text className="text-sm text-gray-700 font-nunitoregular font-normal">
                  {t("walletPaid")} :
                </Text>
                <Text className="text-gray-700 text-sm font-nunitoregular font-normal min-w-[130px] text-right">
                  {t("sar")}{" "}
                  {CUSTOM.setPrice(
                    orderData?.transactionData?.transactionDetails?.walletAmount
                  )}
                </Text>
              </div>
            </>
          ) : null}

          {transactionData?.isOtherSourceUsed ||
          orderData?.transactionData?.paymentStatus == "success" ? (
            <div className="flex flex-row relative justify-end w-full gap-1 mb-2">
              <Text className="text-sm text-gray-700 font-nunitoregular font-normal">
                {t("netPaid")} :
              </Text>
              <Text className="text-gray-700 text-sm font-nunitoregular font-normal min-w-[130px] text-right">
                {t("sar")}{" "}
                {CUSTOM.setPrice(
                  orderData?.transactionData?.transactionDetails?.paidAmount
                )}
              </Text>
            </div>
          ) : null}
        </div>
      </div>
      {/* Show this Order Details - Custom Cake */}
      <div className="flex flex-row xs:flex-col gap-4 items-start justify-start w-full">
        {orderData?.transactionData?.paymentStatus != "success" ? (
          <>
            <div className="flex bg-red-50_01 flex-row gap-2 items-start justify-start w-auto rounded px-3 py-1.5">
              <Img
                className="h-[18px] object-contain w-[18px]"
                src="/images/pending.svg"
                alt="img_logosvisa"
              />
              <Text className="font-nunitomedium text-sm text-gray-900_01 whitespace-nowrap	">
                {t("paymentIncomplete")}
              </Text>
            </div>
          </>
        ) : null}

        {/* {orderData?.transactionData?.paymentStatus == "success" ? (
            <div className="flex flex-row relative justify-end w-full gap-1 mb-2">
              <Text className="text-sm text-gray-700 font-nunitoregular font-normal">
                {t("netPaid")} :
              </Text>
              <Text className="text-gray-700 text-sm font-nunitoregular font-normal min-w-[130px] text-right">
                {t("sar")}{" "}
                {CUSTOM.setPrice(
                  orderData?.orderData?.priceDetails?.total_amount
                )}
              </Text>
            </div>
          ) : null} */}
      </div>
      {/* Show this Order Details - Custom Cake */}

      {/** refund details */}

      {refundWalletHistory.length > 0 ? (
        <>
          <Text className="font-nunitoregular text-sm text-gray-700 mb-2 xs:flex-wrap">
            {t("refundDetails")}
          </Text>
          <div className="p-2 bg-gray-50_02 flex items-center gap-4 p-3 min-w-[320px] xs:min-w-full xs:flex-wrap">
            <Img
              className="object-contain  bg-white rounded px-2 py-3 w-[60px]"
              src="/images/img_volume_black_900_28x28.svg"
              alt="img_logosvisa"
            />
            <div className="relative flex flex-col gap-1">
              <Text className="font-nunitoregular text-sm text-black-900 mb-1">
                {CUSTOM.replaceKeyValue(
                  t("refundInWallet"),
                  "[AMOUNT]",
                  totalWalletRefund
                )}
              </Text>
              {refundWalletHistory.map((items) => {
                return (
                  <Text className="font-nunitoregular text-[12px] text-gray-700">
                    Amount: SAR {CUSTOM.setPrice(items?.amount)} |{" "}
                    {CUSTOM.changeDateFormat(
                      items?.createdAt,
                      "dd/MM/yyyy - hh:mm a"
                    )}
                  </Text>
                );
              })}
            </div>
          </div>
        </>
      ) : null}

      {refundData ? (
        <>
          {refundData?.status == "approved" &&
          refundData?.refundMode == "card" ? (
            <>
              {refundWalletHistory.length == 0 ? (
                <Text className="mt-2 font-nunitoregular text-sm text-gray-700 mb-2 xs:flex-wrap">
                  {t("refundDetails")}
                </Text>
              ) : null}
              <div className="p-2 bg-gray-50_02 flex items-center gap-4 p-3 min-w-[320px] xs:min-w-full xs:flex-wrap">
                <Img
                  className="object-contain  bg-white rounded px-2 py-3 w-[60px]"
                  src={CUSTOM.cardTypeLogo(
                    refundData?.jsonDetails?.payment_option
                  )}
                  alt="img_logosvisa"
                />
                <div className="relative flex flex-col gap-1">
                  <Text className="font-nunitoregular text-sm text-black-900 mb-1">
                    {CUSTOM.replaceKeyValue(
                      t("refundInCard"),
                      "['CARDNO','AMOUNT']",
                      "['" +
                        refundData?.jsonDetails?.card_number +
                        "','" +
                        CUSTOM.setPrice(refundData?.jsonDetails?.amount) +
                        "']",
                      "array"
                    )}
                  </Text>
                  <Text className="font-nunitoregular text-[12px] text-gray-700">
                    Amount: SAR {CUSTOM.setPrice(refundData?.amount)} |{" "}
                    {CUSTOM.changeDateFormat(
                      refundData?.requestUpdatedAt,
                      "dd/MM/yyyy - hh:mm a"
                    )}
                  </Text>
                </div>
              </div>
            </>
          ) : null}

          {refundData?.status != "approved" ? (
            <div className="flex bg-red-50_01  mt-5 flex-row gap-2 items-start justify-start w-auto rounded px-3 py-1.5">
              <Img
                className="h-[18px] object-contain w-[18px]"
                src={
                  refundData?.status == "pending"
                    ? `/images/pending.svg`
                    : "/images/img_close-red.svg"
                }
                alt="img_logosvisa"
              />
              <Text className="font-nunitomedium text-sm text-gray-900_01 whitespace-nowrap	">
                {refundData?.status == "pending"
                  ? t("refundRequestPending")
                  : t("refundRequestRejected")}
              </Text>
            </div>
          ) : null}
        </>
      ) : null}
      {/**refund details end */}
    </>
  );
};

export default TotalBillSection;
