import { Button, Img, Text } from "components";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import globalRequest from "../../../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import * as API from "../../../../utils/helper/Enum";
import { setSnackbar } from "../../../../redux/reducers/snackbar";
import * as CUSTOM from "../../../../utils/helper/custom";
import { AddEditAddress } from "pages/RecipientForm/AddressPopup";
import {
  getGiftOrderData,
  updateGiftOrderData,
} from "../../../../redux/reducers/giftOrderData";

const StepsDeliveryAddress = ({ orderDetails }) => {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  let orderData = useSelector(getGiftOrderData);
  const [inputData, setInputData] = useState({});
  const [addressPopup, setAddAddressPopup] = useState(false);
  
  /**
   * save address data
   */
  const saveAddressData = async () => {
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        `${API.GIFTORDER.CHECK_ADDRESS_IN_ZONE}`,
        "post",
        {
          lattitude: inputData?.lattitude,
          longitude: inputData?.longitude,
        },
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setAddAddressPopup(false);
        dispatch(
          updateGiftOrderData({
            ...orderData,
            addressData: inputData,
            pageStep: 3,
          })
        );
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (inputData?.is_save) {
      saveAddressData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData]);

  return (
    <>
      <div className="relative flex flex-col w-full">
        {/* After fill Delivery Mode */}
        <div className="flex flex-col gap-4 items-center justify-start w-full mb-2">
          <div className="flex flex-row xs:flex-col md:gap-5 items-center xs:items-start justify-start w-full">
            <div className="flex flex-col gap-1 items-start justify-start flex-1">
              <Text className="text-base text-black-900 font-nunitomedium">
                {t("deliveryAddress")}
              </Text>

              <div className="flex flex-col items-start justify-start">
                {orderData?.pageStep > 2 ? (
                  <Text className="text-gray-700 text-sm font-nunitoregular w-[80%] xs:w-full">
                    {CUSTOM.buildFullAddress(orderData?.addressData)}
                  </Text>
                ) : (
                  <Text className="text-gray-700 text-sm font-nunitoregular w-[80%] xs:w-full">
                    {t("forYourGiftAddDeliveryAddress")}
                  </Text>
                )}
              </div>
            </div>
            {orderData?.pageStep > 2 ? (
              <Button
                className="w-auto mx-auto px-3 py-1.5 text-sm sm:mx-0"
                size="sm"
                variant="OutlineBlack"
                onClick={(e) => {
                  setAddAddressPopup(true);
                }}
              >
                {t("change")}
              </Button>
            ) : null}
          </div>
        </div>
        {/* After fill Delivery Mode */}
        {orderData?.pageStep == 2 ? (
          <div className="flex flex-row md:flex-col flex-wrap w-full gap-x-3 md:gap-y-3 mt-[10px] mb-[18px]">
            <div className="mt-0 w-full">
              <div className="w-full max-w-[448px]">
                <div className="w-full flex flex-row xs:flex-col">
                  <Button
                    className="flex flex-1 flex-row items-center justify-start w-full gap-2"
                    onClick={(e) => {
                      setAddAddressPopup(true);
                    }}
                  >
                    <Img
                      className="h-6 w-6"
                      src="/images/img_plus_pink_800.svg"
                      alt="location"
                    />
                    <Text className="common-pointer text-center text-pink-800 text-sm w-auto font-nunitomedium">
                      {t("addAddress")}
                    </Text>
                  </Button>
                  {/* <LoadScript
                    googleMapsApiKey={key}
                    libraries={lib}
                    onLoad={handleLoadScript}
                  >
                    {" "}
                    {isLoad ? (
                      <Autocomplete
                        onLoad={(autocomplete) => {
                          autocompleteRef.current = autocomplete;
                        }}
                        options={{
                          componentRestrictions: { country: "SA" },
                        }}
                        onPlaceChanged={handlePlaceSelect}
                      >
                        <Input
                          required
                          value={inputData?.address}
                          onChange={(e) => {
                            setInputData({
                              ...inputData,
                              address: e.target.value,
                              lattitude: "",
                              longitude: "",
                            });
                          }}
                          startAdornment={
                            <Img
                              className="mr-3 rtl:mr-0 rtl:ml-3 mt-1"
                              src="/images/location_on.png"
                              alt=""
                            />
                          }
                          label={t("streetNumber")}
                          id="google-address-search"
                        />
                      </Autocomplete>
                    ) : null}
                  </LoadScript> */}
                </div>
                {/* <div className="w-full flex flex-row gap-x-5">
                  <div className="w-full flex flex-row gap-x-5 xs:flex-col">
                    <Input
                      required
                      label={t("buildingNumber")}
                      value={inputData?.buildingNumber}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          buildingNumber: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="w-full flex flex-row gap-x-5 xs:flex-col">
                    <Input
                      required
                      label={t("floorSlashApartment")}
                      value={inputData?.floorApartment}
                      onChange={(e) => {
                        setInputData({
                          ...inputData,
                          floorApartment: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div> */}
                {/* <div className="w-full max-w-[350px] mt-3 flex flex-row gap-4 mb-[18px]">
                  <Button
                    className="flex-1 mx-auto min-w-[100px]"
                    size="lg"
                    variant="FillBlack"
                    disabled={
                      !inputData?.longitude ||
                      !inputData?.buildingNumber ||
                      !inputData?.lattitude ||
                      !inputData?.floorApartment
                    }
                    onClick={(e) => {
                      saveAddressData();
                    }}
                  >
                    {t("continue")}
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
        ) : null}
        {addressPopup ? (
          <AddEditAddress
            setInputData={setInputData}
            orderData={orderDetails}
            giftorderData={orderData}
            setAddAddressPopup={setAddAddressPopup}
            pageName={"recipientForm"}
          ></AddEditAddress>
        ) : null}
      </div>
    </>
  );
};

export default StepsDeliveryAddress;
