import React from "react";

const sizeClasses = {
  txtnunitoMedium14: "font-nunitomedium font-normal",
  txtnunitoMedium14Black900: "font-nunitomedium font-normal",
  txtnunitoRegular12Teal400: "font-nunitoregular font-normal",
  txtnunitoMedium16: "font-nunitomedium font-normal",
  txtRobotoMedium14Black900: "font-medium",
  txtRobotoRegular16Deeppurple500: "font-normal",
  txtnunitoRegular14Teal400: "font-nunitoregular font-normal",
  txtnunitoRegular14Black900:
    "font-nunitoregular font-normal text-[14px]",
  txtGochiHandRegular16: "font-gochihand font-normal",
  txtnunitoSemiBold24: "font-nunitosemibold font-normal",
  txtnunitoMedium16WhiteA700: "font-nunitomedium font-normal",
  txtnunitoBold40Black900: "font-nunitobold font-normal",
  txtnunitoRegular12WhiteA700: "font-nunitoregular font-normal",
  txtnunitoBold40: "font-nunitobold font-normal",
  txtnunitoRegular12Red900: "font-nunitoregular font-normal",
  txtnunitoMedium16Gray700: "font-nunitomedium font-normal",
  txtnunitoRegular16Gray700:
    "font-nunitoregular font-normal text-[16px] text-gray-700 sm:text-base",
  txtRobotoRegular16Gray300: "font-normal",
  txtnunitoMedium14Black9007c: "font-nunitomedium font-normal",
  txtRobotoRegular16: "font-normal",
  txtnunitoMedium14Gray700: "font-nunitomedium font-normal",
  txtnunitoRegular16: "font-nunitoregular font-normal",
  txtnunitoMedium16Black9007c: "font-nunitomedium font-normal",
  txtnunitoRegular14: "font-nunitoregular font-normal",
  txtnunitoRegular12Gray90001: "font-nunitoregular font-normal",
  txtnunitoRegular12Gray700: "font-nunitoregular font-normal",
  txtJosefinSansRomanMedium16: "font-josefinsans font-medium",
  txtnunitoRegular12:
    "font-nunitoregular font-normal text-[12px]",
  txtnunitoBold56: "font-nunitobold font-normal",
  txtRobotoMedium11: "font-medium",
  txtnunitoBold34: "font-nunitobold font-normal",
  txtRobotoRegular16WhiteA700: "font-normal",
  txtRobotoMedium14: "font-medium",
  txtnunitoRegular12Pink800: "font-nunitoregular font-normal",
  txtnunitoRegular12RedA700: "font-nunitoregular font-normal",
  txtnunitoRegular14RedA700: "font-nunitoregular font-normal",
  txtnunitoRegular14Pink800: "font-nunitoregular font-normal",
  txtRobotoRegular16Gray700: "font-normal",
  txtnunitoRegular16WhiteA700: "font-nunitoregular font-normal",
  txtnunitoRegular16RedA700: "font-nunitoregular font-normal",
};

const Text = ({ children, className = "", size = "", as, ...restProps }) => {
  const Component = as || "p";

  return (
    <Component
      className={`${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};

export { Text };