import { Button, Img, Text, Line } from "components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as CUSTOM from "../../../../utils/helper/custom";
import { Gift } from "popups/Gift";
// import { Share } from "popups/Share";
// import { ViewImage } from "popups/ViewImage";
const ShareRedBox = ({orderData}) => {
  const { t } = useTranslation();
  const [giftOpen,setgiftOpen]=useState(false);
  // const [ShareModalOpen, setShareModalOpen] = React.useState(false);
  // const handelsetShareModalOpen = () => {
  //   setShareModalOpen(true);
  //   document.body.classList.add("overflow-hidden");
  //   document.getElementById("header-box").classList.add("relative");
  // };
  // const [ViewImageModalOpen, setViewImageModalOpen] = React.useState(false);
  // const handelsetViewImageModalOpen = () => {
  //   setViewImageModalOpen(true);
  //   document.body.classList.add("overflow-hidden");
  //   document.getElementById("header-box").classList.add("relative");
  // };
  return (
    <>
      <div className="bg-red-50 flex flex-col items-center justify-start mt-5 w-full">
        <div
          className="bg-cover bg-right-top-center bg-no-repeat flex flex-col items-start justify-start p-6 w-full"
          style={{
            backgroundImage:
              "url('/images/bg-detail-banner-2.svg'), url(/images/bg-detail-banner-2-2.svg)",
          }}
        >
          <div className="innerflip w-full">
            <div className="relative w-full flex flex-col gap-5">
              <div className="relative flex flex-row gap-4 items-start justify-start w-fulll z-[2]">
                <Button className="bg-red-100 flex h-8 items-center justify-center p-1 w-8 mt-2 relative z-50">
                  <Img
                    className="h-5 w-5"
                    src="/images/person.svg"
                    alt="location_Two"
                  />
                </Button>
                <div className="flex flex-col gap-1 items-start justify-start w-full flex-1">
                  <Text className="font-nunitomedium text-base text-black-900">
                     {CUSTOM.capitalizeWord(orderData?.giftDetails?.recipient_name)}
                  </Text>
                  <Text className="font-nunitoregular text-black-900 text-sm break-all">
                  {CUSTOM.replaceKeyValue(orderData?.giftDetails?.mobile_no,'-'," ")} |  {orderData?.giftDetails?.email} | {orderData?.giftDetails?.occasion_name}
                  </Text>
                  <Text className="text-gray-700 text-xs font-nunitoregular">
                    Recipient Details
                  </Text>
                </div>
              </div>
              {orderData?.recipientStatus == "accepted" ? (
                <>
                  <div className="relative flex flex-row gap-4 items-start justify-start w-fulll z-[1]">
                    <Line className="absolute -top-full h-[70px] xs:h-[100px] left-[15px] rtl:left-auto rtl:right-[15px] w-px ltr:border-l rtl:border-r border-dashed border-gray-900 z-10" />
                    <Button className="bg-red-100 flex h-8 items-center justify-center p-1 w-8 mt-2 relative z-50">
                      <Img
                        className="h-5 w-5"
                        src="/images/location_on.svg"
                        alt="location_Two"
                      />
                    </Button>
                    <div className="flex flex-col gap-1 items-start justify-start w-full flex-1">
                      <Text className="font-nunitomedium text-base text-black-900 w-full md:max-w-[415px]">
                      {orderData?.addressDetails?.address}
                      </Text>
                      <Text className="text-gray-700 text-xs font-nunitoregular">
                        Delivery Address
                      </Text>
                    </div>
                  </div>
                  <div className="relative flex flex-row gap-4 items-start justify-start w-full z-[0]">
                    <Line className="absolute -top-full xs:top-[-160%] xxs:top-[-150%] h-[70px] xs:h-[100px] left-[15px] rtl:left-auto rtl:right-[15px] w-px ltr:border-l rtl:border-r border-dashed border-gray-900 z-10" />
                    <Button className="bg-red-100 flex h-8 items-center justify-center p-1 w-8 relative z-50">
                      <Img
                        className="h-5 w-5"
                        src="/images/event_note.svg"
                        alt="calendar"
                      />
                    </Button>
                    <div className="flex flex-col gap-1 items-start justify-start flex-1">
                      <Text className="font-nunitomedium text-base text-black-900">
                      {CUSTOM.changeDateFormat(orderData?.deliveryDate,'dd/MM/yyyy')}, {CUSTOM.formatTimeRange(orderData?.timeSlotDetails?.openTime,orderData?.timeSlotDetails?.closeTime)} 
                      </Text>
                      <Text className="font-nunitoregular text-gray-700 text-xs">
                        Scheduled Date & Time
                      </Text>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="flex flex-row gap-4 justify-start xs:items-start">
              <div className="relative">
                <Button
                  hover={true}
                  hoverclass="bg-black-900"
                  className="mt-5 ml-11 common-pointer border border-black-900 border-solid cursor-pointer py-[5px] rounded text-sm text-black-900 text-center w-[138px] font-nunitomedium"
                  onClick={(e)=>{
                    setgiftOpen(true);
                  }}
                >
                  {t("previewCard")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {giftOpen?<Gift closepopup={setgiftOpen} Giftdata={orderData?.giftDetails} />:''}
      {/* <ViewImage /> */}
      {/* {ShareModalOpen === true ? (
        <Share closepopup={setShareModalOpen} />
      ) : null}
      {ViewImageModalOpen === true ? (
        <ViewImage closepopup={setViewImageModalOpen} />
      ) : null} */}
    </>
  );
};

export default ShareRedBox;
