import React from "react";
import { Img, Text, Radio } from "components";
import * as CUSTOM from "../../utils/helper/custom";

const SavedAddressesListItem = (props) => {
  const { addressList, selectedAddressData, setSelectedAddressData } = props;

  /**
   * set address data
   */

  const handleAddressSelect = (addressItem, index) => {
    const addressData = {
      homeAddress: {
        address: CUSTOM.buildFullAddress(addressItem),
        itemDetail: addressItem,
      },
      addressIndex: index,
      selected: true
    };
    setSelectedAddressData(addressData);
  };
    
  return (
    <>
    {addressList?.map((addressItem, index) => {
      return (
        <div
          key={`addressUniqueKey-${index}`}
          className={`flex flex-1 xs:flex-col xs:gap-3 flex-row items-center justify-between w-full shadow-bs6 rounded-md p-6 ${
            selectedAddressData?.addressIndex === index &&
            "border border-pink-800"
          }`}
         onClick={() => handleAddressSelect(addressItem, index)}
        >
          <div className="flex flex-1 flex-row items-center sm:items-start justify-start w-full gap-3">
            <Radio
              name={`deliveryMode${index}`}
              checked={selectedAddressData?.addressIndex == index}
              onChange={() => handleAddressSelect(addressItem, index)}
            />
            <Img
              className="h-6 w-6 sm:mt-1"
              src="/images/img_location.svg"
              alt="location"
            />
            <Text className="text-sm sm:text-xs text-black-900 font-nunitoregular">
              {CUSTOM.buildFullAddress(addressItem)}
            </Text>
          </div>
        </div>
      );
    })}
    </>
  );
};
export { SavedAddressesListItem };
