import React, { useState } from "react";
import { Text, Img, List } from "components";
import { useTranslation } from "react-i18next";
import EmptyListOrder from "../EmptyListOrder";
import { useNavigate } from "react-router-dom";
import globalRequest from "../../../../utils/global/globalRequest";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import * as CUSTOM from "../../../../utils/helper/custom";
import * as APIS from "../../../../utils/helper/Enum";
import { useEffect } from "react";

const MyOrdersOrderlist = () => {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const [orderData, setOrderData] = useState([]);
  let [apiLoadStatus, setApiLoadStatus] = useState(true);
  const [inputData, setInputData] = useState({ page: 1, limit: 10 });
  const [isPagination, setIsPagination] = useState(true);

  /**
   * get my orders
   */
  const getMyOrders = async () => {
    let orderDataArray = [];
    dispatch(changeLoader(true));
    apiLoadStatus = true;
    try {
      let response = await globalRequest(
        APIS?.ORDER?.MY_ORDER_LISTING,
        "get",
        {},
        { params: inputData },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        orderDataArray = response?.data?.data;
        setOrderData((pre) => [...pre, ...orderDataArray]);
        setIsPagination(orderDataArray.length ? true : false);
      } else {
        setIsPagination(false);
      }
    } catch (e) {}
    apiLoadStatus = false;
    setApiLoadStatus(apiLoadStatus);
    dispatch(changeLoader(false));
  };

  const getOrderItemNames = (id) => {
    const oData = orderData.find((item) => item?.id == id);
    if (!oData) {
      return ""; // Return an empty string if order data is not found
    }
    if (
      oData.orderType === "custom_cake" ||
      oData.orderType === "custom_cake_gift"
    ) {
      return `1 X ${t("cake")}`;
    }
    const proData = oData.orderDetails.map((item) => {
      const { quantity, orderItemDetails } = item;
      const { product } = orderItemDetails || {};
      const productName = CUSTOM.getdataByLangKey(
        product?.productLocales,
        "",
        "name"
      );
      return `${quantity} X ${productName || ""}`;
    });
    return proData.join(", ");
  };

  /**
   * onscroll pagination get order
   */
  useEffect(() => {
    if (isPagination) {
      getMyOrders();
      const handleScroll = (e) => {
        const scrollHeight = e.target.documentElement.scrollHeight;
        const currentHeight =
          e.target.documentElement.scrollTop + window.innerHeight + 450;
        if (currentHeight + 1 >= scrollHeight) {
          if (!apiLoadStatus) {
            setInputData({ ...inputData, page: Number(inputData?.page) + 1 });
          }
        }
      };
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData?.page]);

  return (
    <>
      <Text className="font-nunitobold leading-[40px] md:leading-[32px] text-2xl md:text-3xl text-black-900 mb-7">
        {t("myOrders")}
      </Text>
      <List
        className="flex flex-col font-nunitoregular gap-5 items-center w-full mb-5"
        orientation="vertical"
      >
        {orderData.map((items, index) => {
          return (
            <div
              className="bg-white-A700 border border-gray-300 border-solid flex flex-1 flex-col items-center justify-start p-[19px] rounded w-full cursor-pointer"
              key={`keyid-${index}-${Math.random()}`}
              onClick={() =>
                navigate(
                  `/my-order-detail/${CUSTOM.base64Encode(items?.id)}`
                )
              }
            >
              <div className="flex flex-col gap-2 items-center justify-start w-full">
                <div className="flex md:flex-col flex-row md:gap-2 items-start justify-between w-full">
                  <Text className="font-nunitomedium leading-[24.00px] text-base text-black-900">
                    {getOrderItemNames(items?.id)}
                  </Text>
                  <div className="flex md:flex-1 flex-row gap-2 items-end justify-end w-[50%] md:w-full">
                    <Text className="font-nunitoregular text-black-900 text-right text-sm">
                      <span className="text-black-900 font-normal">
                        {t(CUSTOM.statusByKey(items?.status))}{" "}
                        {CUSTOM.convertDateString(
                          CUSTOM.getDateFromStatusLog(
                            items?.orderStatusLogs,
                            items?.status
                          ),
                          "dd/MM/yyyy hh:mm a"
                        )}
                      </span>
                    </Text>
                    <Img
                      className="h-6 w-6"
                      alt="check_circle"
                      src={CUSTOM.orderStatusImgages(items?.status)}
                    />
                  </div>
                </div>
                <div className="flex flex-row sm:gap-10 items-start justify-between w-full">
                  <Text className="font-nunitoregular text-base text-black-900">
                    {t("sar")}{" "}
                    {CUSTOM.setPrice(items?.priceDetails?.total_amount)}
                  </Text>
                  <div className="flex flex-row gap-2 items-center justify-start w-auto">
                    <Text className="font-nunitoregular text-gray-700 text-right text-xs w-auto">
                      {items?.orderType?.includes("custom") && items?.deliveryMode == 'pending' ? "Custom" : CUSTOM.ucFirst(items?.deliveryMode) }
                    </Text>
                    <div className="bg-gray-700 min-w-[4px] h-1 rounded-[50%] mt-1"></div>
                    <Text className="font-nunitoregular text-gray-700 text-right text-xs w-auto">
                      #{items?.orderNumber}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </List>
      {/* <Img
        src="/images/Loaderanim.png"
        className="w-12 h-12 animate-spin m-auto"
        alt="loading"
      /> */}
      {orderData.length == 0 && !apiLoadStatus ? (
        <EmptyListOrder
          paragraph={t("shopNewProductsAndPlaceOrderToTrackTheStatusHereDot")}
        />
      ) : null}
    </>
  );
};

export default MyOrdersOrderlist;
