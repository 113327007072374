import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Img } from "components";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Footer from "components/Footer";
import { useTranslation } from "react-i18next";
import ProfileEdit from "../ProfileEdit";
import OrderDetailDelevery from "../AllTabsOption/OrderDetailDelevery";
import { useMediaQuery } from "@mui/material";
import globalRequest from "../../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../redux/reducers/loader";
import * as APIS from "../../../utils/helper/Enum";
import { addDeleteGetLocalStorage, storageKeys } from "utils/global/localData";
import { SuccessOrderPlaced } from "popups/SuccessOrderPlaced";
import { setSnackbar } from "redux/reducers/snackbar";
import { changeTrigger, currentTrigger } from "redux/reducers/trigger";
import * as CUSTOM from "../../../utils/helper/custom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const MyOrderDetail = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const loaderValue = useSelector(currentTrigger);
  const value = React.useState(0)[0];
  const [successOrderPlacedOpen, setSuccessOrderPlacedOpen] = useState(false);
  const { id } = useParams();
  const isMobile = useMediaQuery("(max-width: 990px)");
  const [orderDetails, setOrderDetails] = React.useState(null);
  const [bgColor, setBgColor] = useState("bg-gray-50_02");
  let dispatch = useDispatch();

  /**
   * get order details
   */
  const getOrderDetails = async (id) => {
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        `${APIS?.ORDER?.MY_ORDER_DETAILS}/${CUSTOM.base64Encode(id, "decode")}`,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setOrderDetails(response?.data);
      }
    } catch (e) {}
    dispatch(changeLoader(false));
  };

  const sendPurchaseGaEvent = async (order_number) => {
    try {
      let response = await globalRequest(
        APIS?.ORDER?.SEND_PURCHASE_GA_EVENT,
        "post",
        { order_number, gaData: CUSTOM.getGACookieValues() },
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        console.log("GA event sent");
      }
    } catch (e) {
      console.log(e?.message);
    }
  };

  /**
   * Handle my order change
   */
  const handleChange = (newValue) => {
    addDeleteGetLocalStorage(
      storageKeys.ORDER_TAB,
      newValue.toString(),
      "add",
      "single"
    );
    navigate("/my-order");
  };

  /**
   * get order details when load page
   */
  useEffect(() => {
    getOrderDetails(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * when order status update call same api
   */
  useEffect(() => {
    if (loaderValue == "refetchDetails") {
      getOrderDetails(id);
      dispatch(changeTrigger(""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaderValue]);

  /**
   * Handle scroll
   */
  useEffect(() => {
    const handleScroll = () => {
      const div = document.getElementById("order-box");
      if (div) {
        const rect = div.getBoundingClientRect();
        const isVisible = rect.top <= window.innerHeight && rect.bottom >= 0;

        if (window.scrollY === 0) {
          // At the top of the page
          setBgColor("bg-gray-50_02");
        } else if (isVisible) {
          // Scrolling and div is visible
          setBgColor("bg-transparent");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  //OrderDetailDelevery

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams) {
      const payment_status = urlSearchParams.get("payment_status");
      if (payment_status) {
        const message = urlSearchParams.get("message");
        const order_type = urlSearchParams.get("order_type");
        if (payment_status == "success" && order_type == "gift_order") {
          CUSTOM.sendPaymentStatusGaEvent("success", "order", orderDetails?.orderData?.userId, orderDetails?.orderData?.userDetails);
          sendPurchaseGaEvent(orderDetails?.orderData?.orderNumber);
          setSuccessOrderPlacedOpen(true);
        } else {
          CUSTOM.sendPaymentStatusGaEvent("failed", "order", orderDetails?.orderData?.userId, orderDetails?.orderData?.userDetails);
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: message,
              snackbarState: "error",
            })
          );
        }
        const url = window.location.href;
        let urlEx = url.split("?");
        window.history.pushState({ html: "", pageTitle: "cart" }, "", urlEx[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="bg-white-A700 flex flex-col font-nunitoregular items-center justify-start mx-auto w-full">
        <div
          className={`font-nunitobold relative w-full min-h-[100vh] ${bgColor}`}
        >
          {successOrderPlacedOpen === true &&
          orderDetails?.orderData?.orderNumber ? (
            <SuccessOrderPlaced
              closepopup={setSuccessOrderPlacedOpen}
              orderNumber={orderDetails?.orderData?.orderNumber}
            />
          ) : null}
          <ProfileEdit />
          <div
            id="order-box"
            className="flex flex-col items-center justify-center mx-auto w-full max-w-[1246px] py-[40px] md:py-5 px-[68px] md:px-5 bg-white-A700"
          >
            <div className="w-full bg-gray-100_01 flex flex-row sm:flex-col justify-between">
              <Tabs
                orientation={isMobile ? "horizontal" : "vertical"}
                variant="scrollable"
                value={value}
                scrollButtons={isMobile ? "auto" : false}
                allowScrollButtonsMobile
                aria-label={
                  isMobile
                    ? "scrollable force tabs example"
                    : "Vertical auto  tabs example"
                }
                className="my-order-tabs w-full max-w-[255px] sm:max-w-full sm:bg-gray-100_01 sticky z-[20] xs:z-0 xs:relative xs:top-0 top-[70px] h-fit pl-5 py-5 sm:px-0"
              >
                <Tab
                  className="sm:w-auto sm:max-w-[unset]"
                  onClick={(e) => {
                    handleChange(0);
                  }}
                  label={
                    <>
                      <Img
                        src="/images/img_cart_black_900.svg"
                        className="w-7 h-7"
                      />
                      <span className="block px-[10px] text-left sm:whitespace-nowrap rtl:text-right">
                        {t("myOrders")}
                      </span>
                    </>
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  className="sm:w-auto sm:max-w-[unset]"
                  onClick={(e) => {
                    handleChange(1);
                  }}
                  label={
                    <>
                      <Img
                        src="/images/img_volume_black_900.svg"
                        className="w-7 h-7"
                      />
                      <span className="block px-[10px] text-left sm:whitespace-nowrap rtl:text-right">
                        {t("myOccasions")}
                      </span>
                    </>
                  }
                  {...a11yProps(1)}
                />
                <Tab
                  className="sm:w-auto sm:max-w-[unset]"
                  onClick={(e) => {
                    handleChange(2);
                  }}
                  label={
                    <>
                      <Img src="/images/img_location.svg" className="w-7 h-7" />
                      <span className="block px-[10px] text-left sm:whitespace-nowrap rtl:text-right">
                        {t("myAddresses")}
                      </span>
                    </>
                  }
                  {...a11yProps(2)}
                />
                <Tab
                  className="sm:w-auto sm:max-w-[unset]"
                  onClick={(e) => {
                    handleChange(3);
                  }}
                  label={
                    <>
                      <Img src="/images/img_wallet.svg" className="w-7 h-7" />
                      <span className="block px-[10px] text-left sm:whitespace-nowrap rtl:text-right">
                        {t("wallet")}
                      </span>
                    </>
                  }
                  {...a11yProps(3)}
                />
                <Tab
                  className="sm:w-auto sm:max-w-[unset]"
                  onClick={(e) => {
                    handleChange(4);
                  }}
                  label={
                    <>
                      <Img
                        src="/images/img_payment-menthod.svg"
                        className="w-6 h-6 mx-[2px]"
                      />
                      <span className="block px-[10px] text-left sm:whitespace-nowrap rtl:text-right">
                        {t("managePaymentMenthods")}
                      </span>
                    </>
                  }
                  {...a11yProps(4)}
                />
                <Tab
                  className="sm:w-auto sm:max-w-[unset]"
                  onClick={(e) => {
                    handleChange(5);
                  }}
                  label={
                    <>
                      <Img
                        src="/images/img_computer_black_900_28x28.svg"
                        className="w-7 h-7"
                      />
                      <span className="block px-[10px] text-left sm:whitespace-nowrap rtl:text-right">
                        {t("myCustomCakes")}
                      </span>
                    </>
                  }
                  {...a11yProps(5)}
                />
                <Tab
                  className="sm:w-auto sm:max-w-[unset]"
                  onClick={(e) => {
                    handleChange(6);
                  }}
                  label={
                    <>
                      <Img
                        src="/images/img_bluetooth.svg"
                        className="w-7 h-7"
                      />
                      <span className="block px-[10px] text-left sm:whitespace-nowrap rtl:text-right">
                        {t("preferredLanguage")}
                      </span>
                    </>
                  }
                  {...a11yProps(6)}
                />
              </Tabs>
              <div className="w-full bg-white-A700  p-7 pr-0 rtl:pr-7 rtl:sm:pr-0 rtl:pl-0 sm:pl-0 z-1 items-start justify-between">
                <TabPanel value={value} index={0}>
                  <OrderDetailDelevery
                    orderData={orderDetails}
                    setOrderDetails={setOrderDetails}
                  />
                </TabPanel>
              </div>
            </div>
          </div>
          <Footer className="absolute bottom-[0] flex font-nunitoregular inset-x-[0] items-center justify-center mx-auto w-full" />
        </div>
      </div>
    </>
  );
};

export default MyOrderDetail;
