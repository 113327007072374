import { io } from "socket.io-client";
import * as CUSTOM from "./custom";

let token;
let socket;

const initializeSocket = async () => {
  if (!socket) {
    token = await CUSTOM.getToken();
    socket = io(process.env.REACT_APP_BASEURL, {
      withCredentials: true,
      extraHeaders: {
        authorization: `Bearer ${token}`,
      },
    });
  }
};

const getSocket = () => {
  return socket;
};

const disconnectSocket = () => {
  if (socket) {socket.disconnect(); socket = undefined;}
};

export { initializeSocket, getSocket, disconnectSocket };