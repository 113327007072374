import React, { useState } from "react";
import { Img, Line, Text } from "components";
import { useTranslation } from "react-i18next";
import * as CUSTOM from "../../../../utils/helper/custom";
import { RateReview } from "popups/RateReview";

const ItemsBox = ({ orderData }) => {
  const { t } = useTranslation();
  const [RateReviewModalOpen, setRateReviewModalOpen] = React.useState(false);
  const [productDataForRate, setProductDataForRate] = useState({});

  const handelsetRateReviewModalOpen = (data) => {
    setProductDataForRate(data);
    setRateReviewModalOpen(true);
  };

  const getModifierItems = (productUom) => {
    let allName = [];
    if (productUom?.modifierGroup?.length) {
      productUom?.modifierGroup?.forEach((items) => {
        items?.modifierGroupItems?.forEach((item) => {
          allName.push(
            CUSTOM.getdataByLangKey(item?.modifierGroupItemLocales, "", "name")
          );
        });
      });
    }
    return allName.join(", ");
  };

  const getAllItemName = () => {
    let iName = [];
    orderData?.orderDetails?.forEach((oitem) => {
      oitem?.orderItemDetails?.cakeDetail?.forEach((orderIt) => {
        orderIt?.modifierGroupItems?.forEach((nitem) => {
          iName.push(
            CUSTOM.getdataByLangKey(nitem?.modifierGroupItemLocales, "", "name")
          );
        });
      });
    });
    return iName.join(", ");
  };

  return (
    <>
      <Text className="font-nunitoregular text-[12px] text-gray-700 mt-8 mb-4">
        {orderData?.orderDetails.length} {t("items")}
      </Text>
      {orderData?.orderType == "custom_cake" ||
      orderData?.orderType == "custom_cake_gift" ? (
        <>
          <div className="flex flex-row items-center justify-between mb-4 xs:flex-col xs:gap-4 xs:items-start">
            <div className="flex gap-[16px] items-center">
              <div className="flex flex-col relative w-full gap-1 w-full">
                <div className="flex relative w-full gap-1 w-full">
                  {orderData?.orderDetails.map((oitem, index) =>
                    oitem?.orderItemDetails?.imageLinks?.map(
                      (iitem, iindex) => {
                        return (
                          <>
                            <Img
                              className="h-10 mr-2 w-10 min-w-[40px] mt-1 rounded object-cover"
                              src={iitem}
                              alt="moneyadded_Four"
                            />
                          </>
                        );
                      }
                    )
                  )}
                </div>
                <Text className="text-left text-base text-black-900 font-nunitomedium font-normal rtl:text-right">
                  1 X {t("cake")}
                </Text>
                <Text className="text-left text-gray-700 text-xs font-nunitoregular font-normal rtl:text-right">
                  {getAllItemName()}
                </Text>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {orderData?.orderDetails.map((items, indexs) => {
            return (
              <div
                key={`keyid-${indexs}-${Math.random()}`}
                className="flex flex-row items-center justify-between mb-4 xs:flex-col xs:gap-4 xs:items-start"
              >
                <div className="flex gap-[16px] items-center">
                  <Img
                    className="h-10 w-10 min-w-[40px] mt-1 rounded object-cover"
                    src={CUSTOM.getImage(
                      items?.orderItemDetails?.productUom?.productUomImages?.[0]
                        ?.image || ""
                    )}
                    alt="moneyadded_Four"
                  />
                  <div className="flex flex-col relative w-full gap-1">
                    <Text className="text-left text-base text-black-900 font-nunitomedium font-normal rtl:text-right">
                      {CUSTOM.getdataByLangKey(
                        items?.orderItemDetails?.product?.productLocales,
                        "",
                        "name"
                      )}{" "}
                      ({items?.orderItemDetails?.productUom?.name}) x{" "}
                      {items?.quantity}
                    </Text>
                    <Text className="text-left text-gray-700 text-xs font-nunitoregular font-normal rtl:text-right">
                      {getModifierItems(items?.orderItemDetails?.productUom)}
                    </Text>
                  </div>
                </div>

                {orderData?.status == "delivered" ? (
                  <div className="flex flex-col relative gap-1 self-end">
                    <Text className="text-sm text-right text-gray-700 font-nunitoregular font-normal">
                      {t("sar")} {items?.totalAmount}
                    </Text>
                    {!items?.ratingJson ? (
                      <>
                        <div
                          className="flex gap-1 items-center cursor-pointer"
                          onClick={(e) => {
                            handelsetRateReviewModalOpen({
                              productName: CUSTOM.getdataByLangKey(
                                items?.orderItemDetails?.product
                                  ?.productLocales,
                                "",
                                "name"
                              ),
                              product_image: CUSTOM.getImage(
                                items?.orderItemDetails?.productUom
                                  ?.productUomImages?.[0]?.image || ""
                              ),
                              order_id: orderData?.id,
                              product_id: items?.orderItemDetails?.product?.id,
                              is_add: 1,
                            });
                          }}
                        >
                          <Img
                            className="w-4 h-4"
                            src="/images/ratestar.svg"
                            alt=""
                          />
                          <div className="text-pink-800 text-xs font-normal font-nunitoregular leading-none">
                            Rate & Review
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="flex gap-[6px] items-center cursor-pointer"
                          onClick={(e) => {
                            handelsetRateReviewModalOpen({
                              productName: CUSTOM.getdataByLangKey(
                                items?.orderItemDetails?.product
                                  ?.productLocales,
                                "",
                                "name"
                              ),
                              product_image: CUSTOM.getImage(
                                items?.orderItemDetails?.productUom
                                  ?.productUomImages?.[0]?.image || ""
                              ),
                              order_id: orderData?.id,
                              product_id: items?.orderItemDetails?.product?.id,
                              is_add: 0,
                              reviewData: items?.ratingJson,
                            });
                          }}
                        >
                          <div className="text-gray-700 text-xs font-normal font-nunitoregular leading-none">
                            {t("YouRated")}
                          </div>
                          <div className="flex gap-1 items-center bg-red-50_01 rounded pl-1 pr-2 py-1">
                            <Img
                              className="w-4 h-4"
                              src="/images/star-yellow.svg"
                              alt=""
                            />
                            <div className="text-gray-900_01 text-xs font-normal font-nunitoregular leading-none">
                              {items?.ratingJson?.ratingStar}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ) : null}
              </div>
            );
          })}
        </>
      )}

      {orderData?.additionalNotes ? (
        <div className="relative mt-8 mb-8">
          <div className="p-2 bg-gray-50_02 flex items-center gap-4">
            <Img
              className="h-6 object-contain w-6"
              src="/images/img_thumbsup.svg"
              alt="thumbsup"
            />
            {orderData?.additionalNotes}
          </div>
        </div>
      ) : null}

      <Line className="bg-gray-300 h-px w-full" />
      <div className="flex flex-row items-center justify-between mt-4">
        <Text className="font-nunitoregular text-sm text-black-900">
          {t("itemTotal")}
        </Text>
        <Text className="font-nunitoregular text-sm text-gray-700">
          {t("sar")} {orderData?.priceDetails?.sub_total}
        </Text>
      </div>

      {orderData?.priceDetails?.delivery_fees ? (
        <div className="flex flex-row items-center justify-between mt-4">
          <Text className="font-nunitoregular text-sm text-gray-700">
            {t("deliveryFee")}
          </Text>
          <Text className="font-nunitoregular text-sm text-gray-700">
            {t("sar")} {orderData?.priceDetails?.delivery_fees}
          </Text>
        </div>
      ) : null}

      {orderData?.priceDetails?.vat ? (
        <div className="flex flex-row items-center justify-between mt-4">
          <Text className="font-nunitoregular text-sm text-gray-700">
            {t("taxVAT")}
          </Text>
          <Text className="font-nunitoregular text-sm text-gray-700">
            {t("sar")} {orderData?.priceDetails?.vat}
          </Text>
        </div>
      ) : null}

      {orderData?.priceDetails?.pickup_discount ? (
        <div className="flex flex-row items-center justify-between mt-4">
          <Text className="font-nunitoregular text-sm text-gray-700">
            {t("dropzonediscount")}
          </Text>
          <Text className="font-nunitoregular text-sm text-teal-400">
            - {t("sar")} {orderData?.priceDetails?.pickup_discount}
          </Text>
        </div>
      ) : null}

      {orderData?.priceDetails?.coupon_discount > 0 ? (
        <div className="flex flex-row items-center justify-between mt-4">
          <Text className="font-nunitoregular text-sm text-gray-700">
            {t("couponDiscount")}
          </Text>
          <Text className="font-nunitoregular text-sm text-teal-400">
            - {t("sar")} {orderData?.priceDetails?.coupon_discount}
          </Text>
        </div>
      ) : null}

      {RateReviewModalOpen === true ? (
        <RateReview
          closepopup={setRateReviewModalOpen}
          productData={productDataForRate}
        />
      ) : null}
    </>
  );
};

export default ItemsBox;
