import { Img, List, Text } from "components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { DeleteModal } from "popups/DeleteModal";
import { AddNewCart } from "popups/AddNewCart";
import * as API from "../../../../utils/helper/Enum";
import * as CUSTOM from "../../../../utils/helper/custom";
import globalRequest from "../../../../utils/global/globalRequest";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import { setSnackbar } from "../../../../redux/reducers/snackbar";

const PaymentMathod = () => {
  const { t } = useTranslation();
  const dispatch=useDispatch();
  const [RemoveCardOpen, setRemoveCardOpen] = React.useState(false);
  const [AddNewCardOpen, setAddNewCardOpen] = React.useState(false);
  const [creditOrDebitCards, setCreditCardList] = useState([]);
  const [deleteId, setDeleteId] = useState(0);

  const handelsetRemoveCardOpen = (id = 0) => {
    setDeleteId(id);
    setRemoveCardOpen(true);
    document.body.classList.add("overflow-hidden");
    document.getElementById("header-box").classList.add("relative");
  };
  // const handelsetAddNewCardOpen = () => {
  //   setAddNewCardOpen(true);
  //   document.body.classList.add("overflow-hidden");
  //   document.getElementById("header-box").classList.add("relative");
  // };

  /**
   * get credit card details
   */
  const getCardList = async () => {
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        API.USERS.GET_SAVED_CARD_LIST,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setCreditCardList(response?.data);
      } else {
        setCreditCardList([]);
      }
    } catch (e) {
      setCreditCardList([]);
    }
    dispatch(changeLoader(false));
  };

  /**
   *  Delete payment card by id
   */
  const deleteConfirm = async (type) => {
    if (type == "yes") {
      try {
        let response = await globalRequest(
          `${API.USERS.DELETE_PAYMENT_CARD}/${deleteId}`,
          "delete",
          {},
          {},
          true
        );
        response = response?.data;
        if (response?.status == "SUCCESS") {
          getCardList();
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: response?.message,
              snackbarState: "success",
            })
          );
        }else{ 
          dispatch(
            setSnackbar({
              snackbarOpen: true,
              snackbarMessage: response?.message,
              snackbarState: "error",
            })
          );
        } 
      }catch(e){
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: e?.message,
            snackbarState: "error",
          })
        );
      } 
    }
  };

  useState(() => {
    getCardList();
  }, []);

  return (
    <>
      <div className="flex flex-col w-full mb-7">
        <Text
          className="text-2xl md:text-[22px] text-black-900 sm:text-xl mb-5"
          size="txtnunitoSemiBold24"
        >
          {t("managePaymentMethods")}
        </Text>

        <List
          className="flex flex-col font-nunitomedium gap-4 items-center w-full"
          orientation="vertical"
        >
          <div className="relative w-full">
            <div className="bg-light_blue-50 flex items-center justify-between py-3 px-5 rounded w-full">
              <div className="flex items-center justify-start gap-4">
                <Img
                  className="h-8 w-8"
                  src="/images/img_computer_black_900.svg"
                  alt="computer"
                />
                <Text
                  className="text-base text-black-900"
                  size="txtnunitoMedium16"
                >
                  {t("creditOrDebitCards")}
                </Text>
              </div>
              {/* <Text
                className="text-center text-pink-800 text-sm w-auto cursor-pointer py-2 px-3"
                size="txtnunitoMedium14"
                onClick={() => {
                  handelsetAddNewCardOpen();
                }}
              >
                {t("add")}
              </Text> */}
            </div>
            {creditOrDebitCards.length !=0 ? (
              <div className="border border-t-0 border-solid border-gray-300 w-full rounded-b-lg">
                {creditOrDebitCards.map((items) => {
                  return (
                    <div
                      key={`card-id-key-${items?.id}`}
                      className="flex items-center justify-between p-4 border-b border-solid border-gray-300"
                    >
                      <div className="flex items-center">
                        <Img
                          src={CUSTOM.cardTypeLogo(items?.type)}
                          alt="master"
                        />
                        <Text
                          className="text-base text-black-900 ml-3 rtl:ml-0 rtl:mr-3"
                          size="txtnunitoMedium16"
                        >
                          {items?.cardNumber}
                        </Text>
                      </div>
                      <Text
                        className="text-base text-black-900 text-ellipsis overflow-hidden w-[150px]"
                        size="txtnunitoMedium16"
                      >
                        {items?.cardNumber}
                      </Text>
                      <Text
                        className="text-base text-black-900"
                        size="txtnunitoMedium16"
                      >
                        {CUSTOM.explodeExipryDate(items?.expiryDate)}
                      </Text>
                      <Img
                        className="h-5 w-5 cursor-pointer mx-3"
                        src="/images/img_trash.svg"
                        alt="delete"
                        onClick={() => {
                          handelsetRemoveCardOpen(items?.id);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          {/* <div className="bg-light_blue-50 flex items-center justify-between py-3 px-5 rounded w-full">
            <div className="flex items-center justify-start gap-4">
              <Img
                className="h-8 w-8"
                src="/images/img_computer_black_900.svg"
                alt="computer"
              />
              <Text
                className="text-base text-black-900"
                size="txtnunitoMedium16"
              >
                {t("applePay")}
              </Text>
            </div>
            <Text
              className="text-center text-pink-800 text-sm w-auto cursor-pointer py-2 px-3"
              size="txtnunitoMedium14"
            >
              {t("link")}
            </Text>
          </div>
          <div className="bg-light_blue-50 flex items-center justify-between py-3 px-5 rounded w-full">
            <div className="flex items-center justify-start gap-4">
              <Img
                className="h-8 w-8"
                src="/images/img_computer_black_900.svg"
                alt="computer"
              />
              <Text
                className="text-base text-black-900"
                size="txtnunitoMedium16"
              >
                {t("buyNowPayLater")}
              </Text>
            </div>
            <Text
              className="text-center text-pink-800 text-sm w-auto cursor-pointer py-2 px-3"
              size="txtnunitoMedium14"
            >
              {t("link")}
            </Text>
          </div> */}
        </List>
      </div>
      {AddNewCardOpen === true ? (
        <AddNewCart
          closepopup={setAddNewCardOpen}
          modalTopIcon="images/modal-celebration-icon.svg"
          OccasionTitle={t("addNewOccasion")}
          OccasionParagraph={t("enterBelowDetailsToAddANewOccasionDot")}
        />
      ) : null}
      {RemoveCardOpen === true ? (
        <DeleteModal
          deleteTitle={"Delete Card"}
          deleteParagraph={t("deleteCardAlert")}
          deleteConfirm={deleteConfirm}
          closepopup={setRemoveCardOpen}
        />
      ) : null}
    </>
  );
};

export default PaymentMathod;
