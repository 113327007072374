import { Button, Img, List, Text } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MyCustomCakesCustomcakecard from "./MyCustomCakesCustomcakecard";
import { useEffect, useState } from "react";
import globalRequest from "../../../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../../../redux/reducers/loader";
import * as CUSTOM from "../../../../utils/helper/custom";
import * as APIS from "../../../../utils/helper/Enum";
import { setSnackbar } from "../../../../redux/reducers/snackbar";
import EmptyAll from "pages/EmptyList";
import { currentTrigger } from "redux/reducers/trigger";
import {
  addDeleteGetLocalStorage,
  storageKeys,
} from "../../../../utils/global/localData";

const MyCustomCake = (prop) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const triggerValue = useSelector(currentTrigger);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [apiStatus, setApiStatus] = useState(false);

  /**
   * get question list
   */
  const getMyCakeList = async () => {
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${APIS.CUSTOMCAKE.MY_LIST}`,
        "get",
        {},
        { params: { page: 1, limit: 20000 } },
        true
      );
      response = response?.data; 
      if (response?.status == "SUCCESS") {
        setList(response?.data?.data);
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
    setApiStatus(true);
  };

  useEffect(() => {
    addDeleteGetLocalStorage(storageKeys.ORDER_TAB, {}, "delete");
    getMyCakeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (triggerValue == "getCustomCakeList") {
      getMyCakeList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerValue]);

  return (
    <>
      <div className="flex flex-row font-nunitomedium items-center justify-between w-full mb-7 xs:flex-wrap xs:gap-4">
        <Text
          className="text-2xl md:text-[22px] text-black-900 sm:text-xl"
          size="txtnunitoSemiBold24"
        >
          {t("myCustomCakes")}
        </Text>
        <Button
          className="min-w-[125px] flex items-center justify-center ltr:ml-auto rtl:mr-auto"
          size="md"
          variant="OutlineBlack"
          hover={true}
          hoverclass="bg-black-900"
          onClick={() => navigate("/design-my-cake")}
          leftIcon={
            <Img
              className="h-5 mt-px btn-icon"
              src="/images/img_plus_black_900_20x20.svg"
              alt="plus"
            />
          }
        >
          <Text className="mx-2" as="span">
            {t("designCake")}
          </Text>
        </Button>
      </div>

      {list.length > 0 ? (
        <List
          className="sm:flex-col flex-row gap-[30px] grid sm:grid-cols-2 xs:grid-cols-1 md:grid-cols-2 grid-cols-3 justify-center w-full"
          orientation="horizontal"
        >
          {list.map((items, index) => {
            return (
              <div key={"cake-items" + index}>
                <MyCustomCakesCustomcakecard
                  setValue={prop?.setValue}
                  className=""
                  data={items}
                  userimage={
                    items?.imageLinks.length > 0
                      ? `${CUSTOM.getValidImage(items?.imageLinks[0])}`
                      : "images/default.png"
                  }
                  esttime="Est. Time :"
                  esttime1={`${CUSTOM.minutsToHour(items?.prepTime)} hrs`}
                  estprice="Est. Price :"
                  estprice1={`SAR ${CUSTOM.setPrice(items?.price)}`}
                  buttontext="Place Order"
                />
              </div>
            );
          })}
        </List>
      ) : null}
      {apiStatus && list.length == 0 ? <EmptyAll type="custom-cake" /> : null}
    </>
  );
};

export default MyCustomCake;
