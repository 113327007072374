import { useState, useEffect, useRef } from 'react';
import Dropdown from './Dropdown';

import { Link, useNavigate } from 'react-router-dom';

const MenuItems = ({ items, depthLevel, handlehamburger }) => {
  const [dropdown, setDropdown] = useState(false);
  let navigate = useNavigate();
  let ref = useRef();
  useEffect(() => {
    const handler = (event) => {
      if (
        dropdown &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        setDropdown(false);
      }
    };
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, [dropdown]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setDropdown(false);
  };

  const closeDropdown = (url = "") => {
    if (window.innerWidth < 961) {
      handlehamburger();
      dropdown && setDropdown(false);
    }
    if(url){
      //alert(url)
      navigate(
        `${url}`
      );
    }
  };

  return (
    <li
      className="menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    // onClick={closeDropdown}
    >
      {items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {window.innerWidth < 960 && depthLevel === 0 ? (
              items.title
            ) : (
              <Link to={items.url} onClick={(e) => {
                e.preventDefault();
                closeDropdown(items.url)
              }}>{items.title}</Link>
            )}

            {depthLevel > 0 &&
              window.innerWidth < 5 ? null : depthLevel > 0 &&
                window.innerWidth > 960 ? (
              <img src="/images/arrow_forward.svg" className='arrow-child' />
            ) : (
              <img src="/images/arrow_forward.svg" className='arrow-main'/>
            )}
          </button>
          <Dropdown
            handlehamburger={handlehamburger}
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : !items.url && items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? 'true' : 'false'}
            onClick={() => setDropdown((prev) => !prev)}
          >
            {items.title}{' '}
            {depthLevel > 0 ? (
              <img src="/images/arrow_forward.svg" className='arrow-child'/>
            ) : ( 
              <img src="/images/arrow_forward.svg" className='arrow-main'/>
            )}
          </button>
          <Dropdown
            handlehamburger={handlehamburger}
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          />
        </>
      ) : (
        <Link to={items.url} onClick={(e) => {
          e.preventDefault();
          closeDropdown(items.url)
        }}>{items.title}</Link>
      )}
    </li>
  );
};

export default MenuItems;
