import React, { useState, useEffect, useMemo } from "react";
import {
  initializeSocket,
  getSocket,
  disconnectSocket,
} from "../../../../utils/helper/socket";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
const lib = ["places"];
const key = process.env.REACT_APP_GOOGLE_API_KEY;

const MapContainer = (props) => {
  let { branchDetail, addressDetail, driverDetail } = props;

  const options = useMemo(
    () => ({
      disableDefaultUI: true,
      clickableIcons: false,
      gestureHandling: "cooperative",
      styles: [
        // {
        //   featureType: "poi",
        //   elementType: "labels",
        //   stylers: [{ visibility: "off" }],
        // },
        {
          featureType: "administrative.land_parcel",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [{ visibility: "off" }],
        },
      ],
      layerTypes: ["traffic"],
      mapTypeControlOptions: {
        mapTypeIds: ["roadmap", "satellite"],
      },
    }),
    []
  );

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: key,
    libraries: lib,
  });
  const zoom = useState(12)[0];
  const [driverPosition, setDriverPosition] = useState(null);
  // const [count, setCount] = useState(0);
  const [directions, setDirections] = useState(null);
  //const [customMarkerIcon, setCustomMarkerIcon] = useState(null);
  const origin = useState({
    lat: branchDetail.lat,
    lng: branchDetail.lng,
  })[0];
  const destination = useState({
    lat: addressDetail.lattitude,
    lng: addressDetail.longitude,
  })[0];
  const [customMarkerIconDestination, setCustomMarkerIconDestination] =
    useState(null);
  const [customMarkerIconOrigin, setCustomMarkerIconOrigin] = useState(null);
  const [customMarkerIconDelivery,setCustomMarkerIconDelivery] = useState(null);

  const mapStyles = {
    height: "400px",
    width: "100%",
  };

  //   const onPlaceChanged = (autocomplete, setPoint) => {
  //     const place = autocomplete.getPlace();
  //     setPoint({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
  //   };

  useEffect(() => {
    if (origin && destination && isLoaded) {
      const directionsService = new window.google.maps.DirectionsService();

      directionsService.route(
        {
          origin,
          destination,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
            setDriverPosition(result.routes[0].overview_path[0]);
          } else {
            console.error(`Directions request failed due to ${status}`);
          }
        }
      );
    }
  }, [origin, destination, isLoaded]);

  //   const aa = () => {
  //     setCount(count + 1);
  //     let newLocation = x[count];
  //     // Recalculate the route with the same origin and destination
  //     const recalculatedDirectionsRequest = {
  //       origin: newLocation,
  //       destination,
  //       travelMode: window.google.maps.TravelMode.DRIVING,
  //     };

  //     const directionsService = new window.google.maps.DirectionsService();
  //     directionsService.route(recalculatedDirectionsRequest, (result, status) => {
  //       if (status === window.google.maps.DirectionsStatus.OK) {
  //         setDirections(result);
  //       } else {
  //         console.error(
  //           `Recalculation directions request failed due to ${status}`
  //         );
  //       }
  //     });
  //     setDriverPosition(newLocation);
  //   };

  useEffect(() => {
    // If the socket isn't initialized, we don't set up listeners.
    let socket;
    const socketInit = async () => {
      await initializeSocket();
      socket = getSocket();
      if (!socket) return;

      const onConnect = () => {
        console.log("socket connected");
      };

      const onDisconnect = () => {
        console.log("socket disconnected");
      };
      
      const onDriverLocation = (data) => {
        const { driver_id, live_location } = data;
        if (driver_id == driverDetail.driverId) {
          let newLocation = {
            lat: Number(live_location.latitude),
            lng: Number(live_location.longitude),
          };

          const recalculatedDirectionsRequest = {
            origin: newLocation,
            destination,
            travelMode: window.google.maps.TravelMode.DRIVING,
          };

          const directionsService = new window.google.maps.DirectionsService();
          directionsService.route(
            recalculatedDirectionsRequest,
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);
              } else {
                console.error(
                  `Recalculation directions request failed due to ${status}`
                );
              }
            }
          );
          setDriverPosition(newLocation);
        }
      };

      // Set up event listeners
      if (driverDetail.driverId && driverDetail.driverId>0){
        socket.on("connect", onConnect);
        socket.on("disconnect", onDisconnect);
        socket.on(
          `driver_${driverDetail.driverId}_live_location`,
          onDriverLocation
        );
      };
      }
    socketInit();
    return () => {
      disconnectSocket();
      socket.emit("disconnectUser");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverDetail]);

  //   const hasDriverDeviated = (currentDirections, currentLocation, threshold) => {
  //     if (
  //       !currentDirections ||
  //       !currentDirections.routes ||
  //       currentDirections.routes.length === 0
  //     ) {
  //       return false;
  //     }

  //     const path = currentDirections.routes[0].overview_path;
  //     return (
  //       window.google.maps.geometry.spherical.computeDistanceBetween(
  //         path[0],
  //         currentLocation
  //       ) > threshold
  //     );
  //   };

  useEffect(() => {
    if (isLoaded) {
      const icon = {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: "grey",
        fillOpacity: 0.8,
        scale: 8,
        strokeColor: "white",
        strokeWeight: 2,
      };
      setCustomMarkerIconDestination(icon);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      const icon = {
        url: "/images/Group-48098536.svg", // Replace with the actual path to your PNG icon
        scaledSize: new window.google.maps.Size(30, 30), // Adjust the size as needed
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(20, 20), // Adjust the anchor point as needed
        // rotation: 250
      };
      setCustomMarkerIconOrigin(icon);  
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      const icon = {
        url: "/images/location_on1.svg", // Replace with the actual path to your PNG icon
        scaledSize: new window.google.maps.Size(40, 40), // Adjust the size as needed
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(20, 20), // Adjust the anchor point as needed
      };
      setCustomMarkerIconDelivery(icon);
    }
  }, [isLoaded]);

  if (!isLoaded) {
    return <>loading...</>;
  }
  return (
    isLoaded && (
      <>
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={zoom}
          center={driverPosition || origin}
          options={options}
        >
          {/* <Marker position={origin} /> */}
          {/* <Marker key={"destination"} position={destination} /> */}
          {/* {driverPosition && (
            <Marker position={driverPosition} label="Driver" />
          )} */}
          {directions && (
            <>
              <Marker
                position={directions.request.origin.location}
                label=""
                icon={customMarkerIconOrigin}
              />
              <Marker
                position={directions.request.destination.location}
                label=""
                icon={customMarkerIconDelivery}
              />
              <DirectionsRenderer
                options={{
                  polylineOptions: {
                    strokeColor: "black",
                  },
                  suppressMarkers: true,
                }}
                directions={directions}
              />
              {directions.routes && directions.routes.length > 0 && (
            <Marker
              position={directions.routes[0].legs[0].end_location}
              label=""
              icon={customMarkerIconDestination}
              // customMarkerIconEnd
            />
          )}
            </>
          )}
        </GoogleMap>
        {/* <Button onClick={aa}>Move driver</Button> */}
      </>
    )
  );
};

export default MapContainer;
