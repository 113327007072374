import { useEffect, useState } from "react";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import { CATEGORIES } from "../../utils/helper/Enum";
import * as CUSTOM from "../../utils/helper/custom";
import MenuItems from "components/MenuBar/MenuItems";
import {
  updateCategories,
  getAllCategories,
} from "../../redux/reducers/allCategories";

const NavMenu = (props) => {
  const dispatch = useDispatch();
  const [menuItems, setMenuItem] = useState([]);
  const getAllCatData = useSelector(getAllCategories);

  /**
   *  Get category list
   */
  const getAllCategoriesList = async () => {
    try {
      //dispatch(changeLoader(true));
      let response = await globalRequest(
        CATEGORIES?.GET_ALL_CATEGORIES,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        dispatch(updateCategories(response?.data));
      }
      dispatch(changeLoader(false));
    } catch (e) { }
  };

  useEffect(() => {
    if (getAllCatData.length == 0) {
      getAllCategoriesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const processCategories = (categories, limit) => {
      return categories.slice(0, limit).map((item) => {
        const mainObj = {
          title: CUSTOM.toTitleCase(CUSTOM.getdataByLangKey(item?.categoryLocales, "", "name")),
          url: item?.parentId > 0 ? `/productlist/${item?.slug}` : "",
          id: item?.id,
        };

        if (item?.subcategories?.length > 0) {
          mainObj.submenu = item.subcategories.map((sitem) => {
            const subObj = {
              title: CUSTOM.toTitleCase(CUSTOM.getdataByLangKey(sitem?.categoryLocales, "", "name")),
              url: sitem?.parentId > 0 ? `/productlist/${sitem?.slug}` : "",
              id: sitem?.id,
            };

            if (sitem?.subsubcategories?.length > 0) {
              subObj.submenu = sitem.subsubcategories.map((ssitem) => ({
                title: CUSTOM.toTitleCase(CUSTOM.getdataByLangKey(ssitem?.categoryLocales, "", "name")),
                url: ssitem?.parentId > 0 ? `/productlist/${ssitem?.slug}` : "",
                id: ssitem?.id,
              }));
            }

            return subObj;
          });
        }

        return mainObj;
      });
    };

    const allMenuItems = processCategories(getAllCatData, 5);

    if (getAllCatData.length > 5) {
      allMenuItems.push({
        title: "More",
        url: "",
        id: 0,
        submenu: processCategories(getAllCatData.slice(5), Infinity),
      });
    }

    setMenuItem(allMenuItems);
  }, [getAllCatData]);


  return (
    <>
      <nav className={`${props.navStyles} flex flex-row mobile-menu-parent`}>
        <ul className="menus">
          {menuItems.map((menu, index) => {
            const depthLevel = 0;
            return (
              <MenuItems
                handlehamburger={props.handlehamburger}
                items={menu}
                key={index}
                depthLevel={depthLevel}
              />
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export { NavMenu };
