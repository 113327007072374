import { Button } from "components";
import * as CUSTOM from "../../utils/helper/custom";
import { useTranslation } from "react-i18next";

const Footer1 = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <footer className={props.className}>
        <div className="flex flex-row md:gap-10 items-center justify-between mx-auto my-[11px] max-w-[1110px] w-full">
          <Button
            className={`common-pointer cursor-pointer font-nunitomedium h-12 min-w-[87px] py-2.5 rounded text-base text-black-900 text-center border border-black-900 
            ${props?.activeId == 0 ? "visiblity-hidden" : ""}
            `}
            onClick={(e) => {
              props?.setActiveId(Number(props?.activeId) - 1);
            }}
          >
            {t("back")}
          </Button>
          {!props?.showEstimaebutton && (
            <Button
              className="common-pointer bg-black-900 cursor-pointer font-nunitomedium h-12 py-2.5 px-3.5 rounded text-base text-center text-white-A700 w-auto"
              disabled={props?.disableButton}
              onClick={(e) => {
                props?.setActiveId(Number(props?.activeId) + 1);
              }}
            >
              {t("continue")} (SAR {CUSTOM.setPrice(props?.totalPrice)})
            </Button>
          )}
          {props?.showEstimaebutton && (
            <Button className="common-pointer bg-black-900 cursor-pointer font-nunitomedium h-12 py-2.5 px-3.5 rounded text-base text-center text-white-A700 w-auto"
            disabled={props?.disableButton}
            onClick={(e)=>{
              props?.getEstimate();
            }}
            >
              {t("getEstimate")}
            </Button>
          )}
        </div>
      </footer>
    </>
  );
};

Footer1.defaultProps = {};

export default Footer1;
