import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "pages/NotFound";
import {
  ProtectedRouter,
  ProtectedLoginRouter,
} from "./utils/global/ProtectedRouter";
import Maintenance from "components/Maintenance";
import Header from "components/Header";
import LoginPage from "pages/auth/Login";
import LoginNamePage from "pages/auth/LoginName";
import VerificationPage from "pages/auth/Verification";
import MyOrder from "pages/ProfilePage/MyOrder";
import MyOrderDetail from "pages/ProfilePage/MyOrderDetail";
import Cart from "pages/Cart";
import CartSuccess from "pages/Cart/CartSuccess";
import PaymentResponse from "pages/Cart/CartSuccess/paymentResponse";
import ProductList from "pages/ProductList";
import ProductDetail from "pages/ProductDetail";
import Home from "pages/Home";
import BlogDetail from "pages/BlogDetail";
import BlogList from "pages/BlogList";
import Search from "pages/Search";
import CustomCakeCart from "pages/CustomCakeCart";
import DesignMyOwnCakeQuestionOne from "pages/DesignMyOwnCakeQuestionOne";
import DesignMyOwnCake from "pages/DesignMyOwnCake";
import DesignMyOwnCakeSummary from "pages/DesignMyOwnCakeSummary";
import RecipientFormThankYou from "pages/RecipientFormThankYou";
import RecipientForm from "pages/RecipientForm";
import ContactUs from "pages/ContactUs";
import TermsConditions from "pages/TermsConditions";
import PrivacyPolicy from "pages/PrivacyPolicy";
import MobilePrivacyPolicy from "pages/MobilePrivacyPolicy";
import ReturnPolicy from "pages/ReturnPolicy";
import LandingPage from "pages/Landing";
import BecomeACustomer from "pages/BecomeACustomer";
import CustomCakeOrder from "pages/CustomCakeOrder";
import AllProductListPage from "pages/AllProductList";

const isMaintenanceMode = false;

const ProjectRoutes = () => {
  return (
    <React.Suspense fallback={<></>}>
      <Router>
      {isMaintenanceMode ? (
          <Routes>
            <Route path="*" element={<Maintenance />} />
          </Routes>
        ) : (
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/payment-response" element={<PaymentResponse />} />
          <Route element={<Header />}>
            {/* <Route path="/" element={<LoginPage />} /> */}
            <Route element={<ProtectedRouter />}>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<LoginNamePage />} />
              <Route path="/verify-otp" element={<VerificationPage />} />
            </Route>
            <Route element={<ProtectedLoginRouter />}>
              <Route path="/my-order" element={<MyOrder />} />
              <Route path="/my-order-detail/:id" element={<MyOrderDetail />} />
              <Route path="/custom-cake-order/:id" element={<CustomCakeCart />} />
            </Route>
            <Route path="/" element={<Home />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/cart-success/:slug" element={<CartSuccess />} />
            <Route path="/productlist/:slug" element={<ProductList />} />
            <Route path="/all-products" element={<AllProductListPage />} />
            <Route path="/productdetail/:slug" element={<ProductDetail />} />
            <Route path="/blog-list" element={<BlogList />} />
            <Route path="/blog-detail/:slug" element={<BlogDetail />} />
            <Route path="/search" element={<Search />} />
            <Route element={<ProtectedLoginRouter />}>
              <Route path="/design-my-cake" element={<DesignMyOwnCake />} />
            </Route>
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/terms-and-conditions" element={<TermsConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/return-policy" element={<ReturnPolicy />} />
          </Route>
          <Route
            path="/web-view-cms/:type/:lang"
            element={<MobilePrivacyPolicy />}
          />
          <Route path="/become-customer" element={<BecomeACustomer />} />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/recipientform/:token" element={<RecipientForm />} />
          <Route
            path="/recipientformthankyou"
            element={<RecipientFormThankYou />}
          />
          <Route element={<ProtectedLoginRouter />}>
            <Route
              path="/design-my-custom-cake"
              element={<DesignMyOwnCakeQuestionOne />}
            />
          </Route>
          <Route
            path="/designmyowncakesummary"
            element={<DesignMyOwnCakeSummary />}
          /> 
        </Routes>
      )}
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
