import React, { useState } from "react";
import { Img, Text } from "components";
import { useTranslation } from "react-i18next";
import * as CUSTOM from "../../../../utils/helper/custom";
import { useSelector } from "react-redux";
import YourDetails from "./yourDetails";
import { TryRounded } from "@mui/icons-material";
import { getOrderData } from "../../../../redux/reducers/orderData";

const Account = () => {
  const { t } = useTranslation();
  const orderData = useSelector(getOrderData);
  const [isYourDetails, setIsYourDetails] = useState(false);

  return (
    <>
      <div className="relative flex flex-col w-full">
        {/* After login Up User */}
        <div className="flex flex-col gap-4 items-center justify-start w-full mb-[18px]">
          <div className="flex flex-row md:gap-5 items-center justify-start w-full">
            <div className="flex flex-col gap-1 items-start justify-start flex-1">
              <Text className="text-base text-black-900 font-nunitomedium">
                {t("account")}
              </Text>
              <div className="flex gap-1 flex-row items-center">
                <Text className="text-black-900 text-sm nunitoregular">
                  {CUSTOM.getLoginDataByKey("fullname")}
                </Text>
                <Img
                  className="h-6 w-6"
                  src="/images/img_checkmark_teal_400.svg"
                  alt="checkmark"
                />
              </div>
            </div>
          </div>
          <div className="common-pointer bg-light_blue-50 flex flex-col items-center justify-start py-3 px-6 xs:px-3 w-full">
            <div className="flex flex-row items-center justify-between w-full">
              <div className="flex flex-col gap-1 items-start justify-start">
                <Text
                  className="text-base text-black-900 font-nunitomedium"
                  size="txtnunitoMedium16"
                >
                  {t("yourDetails")}
                </Text>
                <Text className="text-black-900 text-sm nunitoregular">
                  {orderData?.userDetails?.first_name}{" "}
                  {orderData?.userDetails?.last_name} | +966  {orderData?.userDetails?.mobile_no} | {orderData?.userDetails?.email}
                </Text>
              </div>
              <Img
                className="h-6 w-6 rtl:rotate-180"
                src="/images/img_arrowright.svg"
                alt="arrowright"
                onClick={(e) => {
                  setIsYourDetails(TryRounded);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {isYourDetails ? (
        <YourDetails setIsYourDetails={setIsYourDetails}></YourDetails>
      ) : null}
    </>
  );
};

export default Account;
