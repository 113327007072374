import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Img, Input, Text, Radio, CheckboxCustom } from "components";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getOrderSummary } from "../../../../redux/reducers/orderSummary";
import { changeLoader } from "../../../../redux/reducers/loader";
import * as CUSTOM from "../../../../utils/helper/custom";
import { useDispatch, useSelector } from "react-redux";
import { setSnackbar } from "../../../../redux/reducers/snackbar";
import { getAuth } from "../../../../redux/reducers/loginData";
import * as APIS from "../../../../utils/helper/Enum";
import globalRequest from "../../../../utils/global/globalRequest";
import * as CARDVALIDATION from "../../../../utils/helper/payfortValidation";
import ApplePayComponent from "../../../ApplePayComponent/index.jsx";
import { changeTrigger } from "redux/reducers/trigger";
import {
  updateOrderData,
  getOrderData,
} from "../../../../redux/reducers/orderData";

// let isSelected = "";
// const Panal = styled.div`
//   /* Add the selected styles when the component is selected */
//   ${({ isSelected }) => isSelected && `display: flex !important;`}
// `;

// const Tab = styled.div`
//   /* Add the selected styles when the component is selected */
//   ${({ isSelected }) => isSelected && ` `}
// `;

const Payment = (props) => {
  const { isEventSent, setIsEventSent, sendCheckoutGaEvent } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const navigate = useNavigate();
  const [creditCardList, setCreditCardList] = useState([]);
  const orderData = useSelector(getOrderData);
  const [cardData, setCardData] = useState({});
  const [cardError, setCardError] = useState({});
  const [tabbyData, setTabbyData] = useState({});
  const [tabbyError, setTabbyError] = useState({});
  const orderSummary = useSelector(getOrderSummary);
  const [cardTypeError, setCardTypeError] = useState({});
  const [showSavedCard, setShowSavedCard] = useState(true);
  const [activeTab, setActiveTab] = useState(null);
  const [showApplePayButton, setShowApplePayButton] = useState(false);
  const [applePayDetail, setApplePayDetail] = useState({});
  const [applePayEmail, setApplePayEmail] = useState("");
  const [applePayError, setApplePayError] = useState("");

  const handleTabClick = (index) => {
    setCardData({});
    setCardError({});
    setTabbyError({});
    setTabbyData({});
    setCardTypeError({});
    dispatch(updateOrderData({ ...orderData, paymentTabId: index }));
  };

  const sendAddPaymentInfoEvent = () => {
    if (!isEventSent?.addPaymentInfo) {
      setIsEventSent((prev)=>({...prev, addPaymentInfo: true}));
      sendCheckoutGaEvent("add_payment_info");
    }
  };

  // const handleInfoIconClick = (price) => {
  //   openTabbyPopup(CUSTOM.setPrice(orderSummary?.total_amount), "SAR"); // Pass the dynamic price and currency
  // };

  // const openTabbyPopup = (price, currency) => {
  //   const tabbyButton = document.querySelector(
  //     '[data-tabby-info="installments"]'
  //   );
  //   if (tabbyButton) {
  //     // Set the dynamic price and currency using dataset attributes
  //     tabbyButton.dataset.tabbyPrice = price;
  //     tabbyButton.dataset.tabbyCurrency = currency;
  //     tabbyButton.click();
  //   }
  // };

  const checkCartType = (cardNo) => {
    let res = CUSTOM.getCardType(cardNo);
    setCardTypeError(res);
  };

  /**
   * send gift order
   */
  const sendGift = async () => {
    dispatch(changeLoader(true));
    let input = {
      guest_id: CUSTOM.getDeviceID(),
      user_id: auth?.id,
      branch_id: 0,
      address_id: 0,
      drop_zone_id: 0,
      coupon_code_id: orderData?.couponCodeId ? orderData?.couponCodeId : 0,
      coupon_code: orderData?.couponCode ? orderData?.couponCode : "",
      delivery_mode: "gift", //delivery , pickUp , gift
      delivery_type: "", //fastest/schedule
      delivery_date: "",
      delivery_time_slot_id: orderData?.timeSloat?.id || "",
      additional_notes: orderData?.notes,
      payment_method: "",
      wallet_use: 0,
      device_type: "web",
      order_type: "gift",
      user_details: {
        first_name: orderData?.userDetails?.first_name,
        last_name: orderData?.userDetails?.last_name,
        mobile_no: orderData?.userDetails?.mobile_no,
        email: orderData?.userDetails?.email,
      },
      gift_details: orderData?.buying_for_love,
      order_summary_total_amount: orderSummary?.total_amount,
    };

    // let modifiedGiftDetails = { ...input.gift_details };
    // modifiedGiftDetails.mobile_no = `${modifiedGiftDetails.country_code}-${modifiedGiftDetails.mobile_no}`;
    // input = {
    //   ...input,
    //   gift_details: modifiedGiftDetails,
    // };

    //let cardInput = { ...input, ...cartData };
    let response = await globalRequest(
      APIS.ORDER.PAY_PAYMENT,
      "post",
      input,
      {},
      true
    );
    response = response?.data;
    if (response?.status == "SUCCESS") {
      let order_number = response?.data?.order_number;
      navigate(`/cart-success/${order_number}`);
    } else {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: response?.message,
          snackbarState: "error",
        })
      );
      dispatch(changeLoader(false));
    }
  };

  const sendPurchaseGaEvent = async (order_number) => {
    try {
      let response = await globalRequest(
        APIS?.ORDER?.SEND_PURCHASE_GA_EVENT,
        "post",
        { order_number, gaData: CUSTOM.getGACookieValues() },
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        console.log("GA event sent");
      }
    } catch (e) {
      console.log(e?.message);
    }
  };

  /**
   * payVia wallet
   */
  const payViaWallet = async () => {
    dispatch(changeLoader(true));
    try {
      sendAddPaymentInfoEvent();
      let input = {
        guest_id: CUSTOM.getDeviceID(),
        user_id: auth?.id,
        branch_id: orderData?.timeSloat?.branchId,
        address_id:
          orderData?.delivery_mode == "home_office"
            ? orderData?.home_office?.itemDetail?.id
            : orderData?.home_office?.id,
        drop_zone_id:
          orderData?.delivery_mode == "pickup_point"
            ? orderData?.home_office?.id
            : 0,
        coupon_code_id: orderData?.couponCodeId ? orderData?.couponCodeId : 0,
        coupon_code: orderData?.couponCode ? orderData?.couponCode : "",
        delivery_mode: CUSTOM.deliveryMethod(orderData?.delivery_mode), //delivery , pickUp , gift
        delivery_type: orderData?.delivery_type, //fastest/schedule
        delivery_date: orderData?.delivery_date,
        delivery_time_slot_id: orderData?.timeSloat?.id || "",
        additional_notes: orderData?.notes,
        payment_method: "wallet",
        wallet_use: orderData?.iswallet,
        device_type: "web",
        order_type: "regular",
        user_details: {
          first_name: orderData?.userDetails?.first_name,
          last_name: orderData?.userDetails?.last_name,
          mobile_no: orderData?.userDetails?.mobile_no,
          email: orderData?.userDetails?.email,
        },
        order_summary_total_amount: orderSummary?.total_amount,
      };
      let response = await globalRequest(
        APIS.ORDER.PAY_PAYMENT,
        "post",
        input,
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        let order_number = response?.data?.order_number;
        sendPurchaseGaEvent(order_number);
        navigate(`/cart-success/${order_number}`);
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  const verifyCard = () => {
    const newErrors = {};
    if (!cardData?.token_name) {
      if (cardData.card_number) {
        let resValidation = CARDVALIDATION.validateCard(cardData.card_number);
        if (!resValidation?.validity) {
          newErrors.card_number = t("cardNumberError");
        }
      } else {
        newErrors.card_number = t("cardNumberErrorEmpty");
      }
      if (cardData.expiration_date) {
        if (!CARDVALIDATION.validateExpiryDate(cardData.expiration_date)) {
          newErrors.date = t("cardDateError");
        }
      } else {
        newErrors.date = t("cardDateErrorEmpty");
      }
      if (!cardData.card_holder) {
        newErrors.card_holder = t("cardHolderError");
      } else {
        let cartHolderValidation = CARDVALIDATION.validateHolderName(
          cardData.card_holder
        );
        if (!cartHolderValidation?.validity) {
          newErrors.card_holder = t("cardNumberError");
        }
      }
    }
    if (cardData.cvv) {
      if (!cardData.cvv.match(/^\d{3,4}$/)) {
        newErrors.cvv = t("cvvNumberError");
      }
    } else {
      newErrors.cvv = t("cvvNumberErrorEmpty");
    }
    if (Object.keys(newErrors).length > 0) {
      setCardError(newErrors);
      return false;
    }
    return true;
  };

  const verifyTabbyFields = () => {
    let newErrors = {};
    if (tabbyData?.email) {
      if (!CUSTOM.isValidField(tabbyData?.email, "email")) {
        newErrors.email = t("email_name_required");
      }
    } else {
      newErrors.email = t("email_name_required");
    }

    if (tabbyData?.phone) {
      if (tabbyData?.phone.length != 9) {
        newErrors.phone = t("mobile_no_length_error");
      }
    } else {
      newErrors.phone = t("mobile_no_required");
    }

    if (Object.keys(newErrors).length > 0) {
      setTabbyError(newErrors);
      return false;
    }
    return true;
  };

  /**
   *  pay via card or tabby
   */
  const payViaOtherSource = async (type = "", applePayData, session) => {
    if (type != "apple_pay") {
      if (type == "tabby") {
        if (!verifyTabbyFields()) {
          return;
        }
      } else if (!verifyCard()) {
        return;
      }
    }
    dispatch(changeLoader(true));
    dispatch(changeTrigger("updateinlocal"));
    try {
      sendAddPaymentInfoEvent();
      let input = {
        applePayData: applePayData,
        card_security_code: cardData?.token_name ? cardData?.cvv : "",
        guest_id: CUSTOM.getDeviceID(),
        user_id: auth?.id,
        branch_id: orderData?.timeSloat?.branchId,
        address_id:
          orderData?.delivery_mode == "home_office"
            ? orderData?.home_office?.itemDetail?.id
            : orderData?.home_office?.id,
        drop_zone_id:
          orderData?.delivery_mode == "pickup_point"
            ? orderData?.home_office?.id
            : 0,
        coupon_code_id: orderData?.couponCodeId ? orderData?.couponCodeId : 0,
        coupon_code: orderData?.couponCode ? orderData?.couponCode : "",
        delivery_mode: CUSTOM.deliveryMethod(orderData?.delivery_mode), //delivery , pickUp , gift
        delivery_type: orderData?.delivery_type, //fastest/schedule
        delivery_date: orderData?.delivery_date,
        delivery_time_slot_id: orderData?.timeSloat?.id || "",
        additional_notes: orderData?.notes,
        payment_method:
          type == "tabby"
            ? "tabby"
            : type == "apple_pay"
            ? "apple_pay"
            : "card",
        wallet_use: orderData?.iswallet,
        device_type: "web",
        order_type: "regular",
        user_details: {
          first_name: orderData?.userDetails?.first_name,
          last_name: orderData?.userDetails?.last_name,
          mobile_no: orderData?.userDetails?.mobile_no,
          email: orderData?.userDetails?.email,
        },
        applePayEmail: applePayEmail,
        order_summary_total_amount: orderSummary?.total_amount,
      };
      input = { ...input, ...cardData };
      if (type == "tabby") {
        input.tabby_phone_number = tabbyData?.phone;
        input.tabby_email = tabbyData?.email;
      }
      let response = await globalRequest(
        APIS.ORDER.PAY_PAYMENT,
        "post",
        input,
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        dispatch(changeTrigger("updateinlocal"));
        if (response?.data?.otp_link) {
          window.location.href = response?.data?.otp_link;
        } else if (response?.data?.is_card === 1) {
          let cData = {
            ...cardData,
            ...response?.data?.cardData,
            is_submit: 1,
          };
          setCardData(cData);
        } else if (response?.data?.is_tabby === 1) {
          let cData = {
            ...tabbyData,
            ...response?.data?.cardData,
            is_submit_tabby: 1,
          };
          setTabbyData(cData);
        } else {
          if (type == "apple_pay") {
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
            CUSTOM.sendPaymentStatusGaEvent("success", "order", auth?.id, orderData?.userDetails);
          }
          let order_number = response?.data?.order_number;
          sendPurchaseGaEvent(order_number);
          navigate(`/cart-success/${order_number}`);
        }
      } else {
        if (type == "apple_pay") {
          session.completePayment(window.ApplePaySession.STATUS_FAILURE);
          CUSTOM.sendPaymentStatusGaEvent("failed", "order", auth?.id, orderData?.userDetails);
        }
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      if (type == "apple_pay") {
        session.completePayment(window.ApplePaySession.STATUS_FAILURE);
      }
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    dispatch(changeLoader(false));
  };

  /**
   * get credit card details
   */
  const getCardList = async () => {
    try {
      let response = await globalRequest(
        APIS.USERS.GET_SAVED_CARD_LIST,
        "get",
        {},
        {},
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setCreditCardList(response?.data);
      } else {
        setCreditCardList([]);
      }
    } catch (e) {}
  };

  /**
   * submit payment form
   */
  const submitPaymentForm = () => {
    let form = document.getElementById("aps_payment_form");
    if (form) {
      form.submit();
    }
  };

  const submitPaymentFormTabby = () => {
    let form = document.getElementById("aps_payment_form_tabby");
    if (form) {
      form.submit();
    }
  };

  const checkApplePayEmail = (email) => {
    if (email) {
      if (!CUSTOM.isValidField(email, "email")) {
        setApplePayError(t("please_enter_valid_email"));
      } else {
        setApplePayError("");
      }
    } else {
      setApplePayError(t("email_name_required"));
    }
  };

  useEffect(() => {
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      setShowApplePayButton(true);
    }
  }, []);

  /**
   * is show card tab
   */
  useEffect(() => {
    let isShow = false;
    if (orderData?.iswallet) {
      if (orderSummary?.wallet_balance < orderSummary?.total_amount) {
        isShow = true;
      }
    } else {
      isShow = true;
    }
    setShowSavedCard(isShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderData]);

  /**
   * get credit/debit card details
   */
  useEffect(() => {
    if (auth?.id > 0) {
      getCardList();
    } else {
      setCreditCardList([]);
    }
  }, [auth]);

  /**
   * set active tab for card
   */
  useEffect(() => {
    setActiveTab(orderData?.paymentTabId ? orderData?.paymentTabId : null);
  }, [orderData?.paymentTabId]);

  /**
   * date formate change for payfort
   */
  useEffect(() => {
    let edate = "";
    if (cardData?.expiration_date) {
      let expdata = cardData?.expiration_date.split("/");
      if (expdata.length > 1) {
        edate = `${expdata[1]}${expdata[0]}`;
      }
    }
    setCardData({ ...cardData, edate: edate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardData?.expiration_date]);

  useEffect(() => {
    if (tabbyData?.is_submit_tabby) {
      submitPaymentFormTabby();
    }
  }, [tabbyData]);

  useEffect(() => {
    if (cardData?.is_submit) {
      submitPaymentForm();
    }
  }, [cardData]);

  useEffect(() => {
    let applePayDataObj = {};
    if (orderData?.iswallet) {
      applePayDataObj.amount = CUSTOM.setPrice(
        orderSummary?.total_amount - orderSummary?.wallet_balance
      );
    } else {
      applePayDataObj.amount = CUSTOM.setPrice(orderSummary?.total_amount);
    }
    setApplePayDetail(applePayDataObj);
  }, [orderData, orderSummary]);

  return (
    <>
      {showSavedCard ? (
        <>
          <form
            method="post"
            action={`${
              process.env.REACT_APP_PAYFORT_MODE == "TEST"
                ? process.env.REACT_APP_PAYFORT_DEVLOPMENT
                : process.env.REACT_APP_PAYFORT_PRODUCTION
            }/FortAPI/paymentPage`}
            id="aps_payment_form"
            name="aps_payment_form"
            className="hidden"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: CUSTOM.paymentFormHtml(cardData),
              }}
            ></span>
          </form>
          <form
            method="post"
            action={`${
              process.env.REACT_APP_PAYFORT_MODE == "TEST"
                ? process.env.REACT_APP_PAYFORT_DEVLOPMENT
                : process.env.REACT_APP_PAYFORT_PRODUCTION
            }/FortAPI/paymentPage`}
            id="aps_payment_form_tabby"
            name="aps_payment_form_tabby"
            className="hidden"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: CUSTOM.paymentFormHtmlTabby(tabbyData),
              }}
            ></span>
          </form>
        </>
      ) : null}

      <div className="relative flex flex-col w-full">
        <div className="flex flex-row xs:flex-col xs:items-start gap-4 items-center justify-between w-full mb-4 px-6 xs:px-4">
          <div className="flex flex-col gap-1 items-start justify-start flex-1">
            <Text className="text-base text-black-900 font-nunitomedium">
              {t("payment")}
            </Text>
            <div className="flex gap-1 flex-row items-center">
              <Text className="text-black-900 text-sm font-nunitoregular">
                {t("selectAnOptionToPay")}
              </Text>
            </div>
          </div>
          {orderData?.pageStep > 3 ? (
            <Text className="w-auto text-base text-black-900 font-nunitomedium whitespace-nowrap">
              {t("sar")} {CUSTOM.setPrice(orderSummary?.total_amount)}
            </Text>
          ) : null}
        </div>

        {orderData?.pageStep > 3 &&
        orderData?.delivery_mode != "buying_for_love" ? (
          <div className="flex flex-col items-center justify-between w-full mt-0.5 px-6 xs:px-4">
            {auth?.id > 0 ? (
              <>
                <div className="flex flex-row items-start justify-start w-full pb-3.5 border-b ">
                  <CheckboxCustom
                    checked={orderData?.iswallet == 1}
                    disabled={
                      orderSummary?.wallet_balance == 0 ||
                      !orderSummary?.wallet_balance
                    }
                    onChange={(e) => {
                      if (e.target.checked) {
                        dispatch(
                          updateOrderData({ ...orderData, iswallet: 1 })
                        );
                      } else {
                        dispatch(
                          updateOrderData({ ...orderData, iswallet: 0 })
                        );
                      }
                    }}
                  />
                  <div className="flex flex-col items-start justify-start flex-1 -ml-4 rtl:ml-0 rtl:-mr-4 mt-2">
                    <Text className="text-base text-black-900 font-nunitoregular">
                      {t("wallet")}
                    </Text>
                    <div className="flex flex-row xs:flex-col gap-1 items-start justify-start flex-1">
                      <Text className="text-gray-700 text-sm font-nunitoregular">
                        {t("availableBalance")}
                      </Text>
                      <Text className="text-black-900 text-sm font-nunitomedium">
                        {t("sar")}{" "}
                        {CUSTOM.setPrice(orderSummary?.wallet_balance)}
                      </Text>
                    </div>

                    {orderData?.iswallet && !showSavedCard ? (
                      <div className="w-full mt-3 max-w-[242px]">
                        <Button
                          className="flex-1 mx-auto xs:w-full"
                          size="lg"
                          variant="FillBlack"
                          onClick={(e) => {
                            payViaWallet();
                          }}
                        >
                          {t("payNow")} {t("sar")} (
                          {CUSTOM.setPrice(orderSummary?.total_amount)})
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}

            {showSavedCard ? (
              <>
                {orderData?.iswallet ? (
                  <div className="flex flex-row gap-4 items-center justify-between w-full mt-6 mb-4">
                    <Text className="text-base text-black-900 font-nunitomedium">
                      {t("payRemainingAmountWith")}
                    </Text>
                    <Text className="w-auto text-base text-black-900 font-nunitomedium whitespace-nowrap">
                      {t("sar")}{" "}
                      {CUSTOM.setPrice(
                        orderSummary?.total_amount -
                          orderSummary?.wallet_balance
                      )}
                    </Text>
                  </div>
                ) : null}

                {creditCardList?.map((items, indexs) => {
                  indexs = indexs + 2;
                  return (
                    <div
                      className="common-pointer flex flex-row gap-3 items-start justify-start w-full pb-3.5 border-b mt-2"
                      isSelected={activeTab === indexs}
                    >
                      <Radio
                        name="paymentMode"
                        checked={activeTab === indexs}
                        onChange={() => handleTabClick(indexs)}
                      />
                      <div className="flex flex-col items-start gap-1 justify-start flex-1 mt-0.5">
                        <Text className="text-base text-black-900 font-nunitoregular">
                          {items?.type}
                        </Text>
                        <div className="flex flex-row gap-2 items-center justify-start flex-1">
                          <Text className="text-gray-700 text-sm font-nunitoregular">
                            {items?.cardNumber}
                          </Text>
                          <Text className="text-black-900 text-sm font-nunitomedium">
                            <Img
                              className="h-6"
                              src={CUSTOM.cardTypeLogo(items?.type)}
                              alt="signal"
                            />
                          </Text>
                        </div>

                        {activeTab == indexs ? (
                          <div className="flex flex-col items-start justify-start mt-5 mb-[18px] xs:-ml-7">
                            <div className="max-w-[280px] w-full">
                              <Input
                                required
                                label={t("cvv")}
                                type="password"
                                value={cardData?.cvv}
                                error={cardError?.cvv}
                                onChange={(e) => {
                                  setCardData({
                                    ...cardData,
                                    token_name: items?.cardToken,
                                    cvv: CUSTOM.strFormat(
                                      e.target.value,
                                      "max:4|number"
                                    ),
                                  });
                                  setCardError({ ...cardError, cvv: "" });
                                }}
                              />
                            </div>
                            <div className="w-full max-w-[242px]">
                              <Button
                                className="flex-1 mx-auto xs:w-full"
                                size="lg"
                                variant="FillBlack"
                                onClick={(e) => {
                                  payViaOtherSource();
                                }}
                              >
                                {t("payNow")} (SAR{" "}
                                {CUSTOM.setPrice(orderSummary?.total_amount)})
                              </Button>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
                <div
                  className="common-pointer flex flex-row gap-3 items-start justify-start w-full pt-5 pb-5 border-b "
                  isSelected={activeTab === 1111}
                >
                  <Radio
                    className="flex"
                    name="paymentMode"
                    checked={activeTab === 1111}
                    onChange={() => handleTabClick(1111)}
                  />
                  <div className="flex flex-col items-start gap-1 justify-start flex-1 -mt-1">
                    <Text className="text-base text-black-900 font-nunitoregular">
                      {t("creditDebitCard")}
                    </Text>
                    {activeTab == 1111 ? (
                      <div className="hidden111 max-w-[580px] w-full flex flex-col items-start justify-start mt-6 mb-[18px]">
                        <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-5 w-full mb-2">
                          <TextField
                            required
                            label={t("cardNumber")}
                            value={cardData?.card_number}
                            error={!!cardError?.card_number}
                            helperText={cardError?.card_number || " "}
                            onChange={(e) => {
                              checkCartType(e.target.value);
                              setCardData({
                                ...cardData,
                                card_number: CUSTOM.strFormat(
                                  e.target.value,
                                  "number|max:16"
                                ),
                              });
                              setCardError({ ...cardError, card_number: "" });
                            }}
                            InputProps={{
                              endAdornment: cardTypeError?.image && (
                                <img
                                  src={cardTypeError?.image}
                                  className="h-5 rtl:ml-3 card-type-img-in-card-no"
                                  alt="Card Type Error Image"
                                />
                              ),
                            }}
                          />
                          <TextField
                            required
                            label={t("cardHolderName")}
                            value={cardData?.card_holder}
                            error={!!cardError?.card_holder}
                            helperText={cardError?.card_holder || " "}
                            onChange={(e) => {
                              setCardData({
                                ...cardData,
                                card_holder: CUSTOM.strFormat(
                                  e.target.value,
                                  "text|max:50"
                                ),
                              });
                              setCardError({ ...cardError, card_holder: "" });
                            }}
                          />
                        </div>
                        <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-5 w-full mb-5">
                          <TextField
                            required
                            label={t("expiryDate(MM/YY)")}
                            value={cardData?.expiration_date || ""}
                            error={!!cardError?.date}
                            helperText={cardError?.date || " "}
                            onChange={(e) => {
                              setCardData({
                                ...cardData,
                                expiration_date: CUSTOM.strFormat(
                                  e.target.value,
                                  "max:15|expiry_date"
                                ),
                              });
                              setCardError({ ...cardError, date: "" });
                            }}
                          />
                          <TextField
                            required
                            label={t("cvv")}
                            type="password"
                            value={cardData?.cvv}
                            error={!!cardError?.cvv}
                            helperText={cardError?.cvv || " "}
                            onChange={(e) => {
                              setCardData({
                                ...cardData,
                                cvv: CUSTOM.strFormat(
                                  e.target.value,
                                  "max:4|number"
                                ),
                              });
                              setCardError({ ...cardError, cvv: "" });
                            }}
                          />
                        </div>
                        {auth?.id > 0 ? (
                          <div className="flex items-center justify-start w-full -mt-3">
                            <CheckboxCustom
                              checked={cardData?.remember_me == "YES"}
                              onChange={(e) => {
                                setCardData({
                                  ...cardData,
                                  remember_me: e.target.checked ? "YES" : "NO",
                                });
                              }}
                            />
                            <Text className="text-black-900 text-base font-nunitoregular -ml-4 rtl:ml-0 rtl:-mr-4 -mt-1">
                              {t("saveCardDetailsForFuturePurpose")}
                            </Text>
                          </div>
                        ) : null}

                        <div className="w-full max-w-[242px] mt-5">
                          <Button
                            className="flex-1 mx-auto"
                            size="lg"
                            variant="FillBlack"
                            onClick={(e) => {
                              payViaOtherSource();
                            }}
                          >
                            {t("payNow")} (SAR{" "}
                            {CUSTOM.setPrice(orderSummary?.total_amount)})
                          </Button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                {
                  <div
                    className="common-pointer flex flex-row gap-3 items-start justify-start w-full pt-5 pb-5 border-b "
                    isSelected={activeTab === 2222}
                  >
                    <Radio
                      className="flex"
                      name="paymentMode"
                      checked={activeTab === 2222}
                      onChange={() => handleTabClick(2222)}
                    />
                    <div className="flex flex-col items-start gap-1 justify-start flex-1 -mt-1">
                      <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-0 w-full mb-0">
                        {/* <img src="/images/tabby-logo.svg" alt="tabby-logo" style={{ maxWidth: '45%', maxHeight: '40%' }}/> */}
                        <Text className="text-base text-black-900 font-nunitoregular">
                          {t("payWithTabby")}
                        </Text>
                        {/* <InfoIcon onClick={handleInfoIconClick} /> */}
                      </div>
                      {activeTab == 2222 ? (
                        <div className="hidden111 max-w-[580px] w-full flex flex-col items-start justify-start mt-6 mb-[18px]">
                          <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-5 w-full mb-2">
                            <TextField
                              required
                              label={t("Phone")}
                              type="text"
                              value={tabbyData?.phone}
                              error={!!tabbyError?.phone}
                              helperText={tabbyError?.phone || " "}
                              onChange={(e) => {
                                setTabbyData({
                                  ...tabbyData,
                                  phone: CUSTOM.strFormat(
                                    e.target.value,
                                    "max:9|number"
                                  ),
                                });
                                setTabbyError({ ...tabbyError, phone: "" });
                              }}
                            />
                            <TextField
                              required
                              label={t("Email")}
                              type="text"
                              value={tabbyData?.email}
                              error={!!tabbyError?.email}
                              helperText={tabbyError?.email || " "}
                              onChange={(e) => {
                                setTabbyData({
                                  ...tabbyData,
                                  email: CUSTOM.strFormat(
                                    e.target.value,
                                    "max:50|email"
                                  ),
                                });
                                setTabbyError({ ...tabbyError, email: "" });
                              }}
                            />
                          </div>
                          <div className="w-full max-w-[242px] mt-5">
                            <Button
                              className="flex-1 mx-auto"
                              size="lg"
                              variant="FillBlack"
                              onClick={(e) => {
                                payViaOtherSource("tabby");
                              }}
                            >
                              {t("payNow")} (SAR{" "}
                              {CUSTOM.setPrice(orderSummary?.total_amount)})
                            </Button>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                }
                {!!showApplePayButton && (
                  <div
                    className="common-pointer flex flex-row gap-3 items-start justify-start w-full pt-5 pb-5 border-b "
                    isSelected={activeTab === 3333}
                  >
                    <Radio
                      className="flex"
                      name="paymentMode"
                      checked={activeTab === 3333}
                      onChange={() => handleTabClick(3333)}
                    />
                    <div className="flex flex-col items-start gap-1 justify-start flex-1 -mt-1">
                      <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-0 w-full mb-0">
                        {/* <img src="/images/tabby-logo.svg" alt="tabby-logo" style={{ maxWidth: '45%', maxHeight: '40%' }}/> */}
                        <Text className="text-base text-black-900 font-nunitoregular">
                          {"Pay with Apple Pay"}
                        </Text>
                        {/* <InfoIcon onClick={handleInfoIconClick} /> */}
                      </div>
                      {activeTab == 3333 ? (
                        <div className="hidden111 max-w-[580px] w-full flex flex-col items-start justify-start mt-6 mb-[18px]">
                          {!auth?.email ? (
                            <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-5 w-full mb-2">
                              <TextField
                                required
                                label={t("Email")}
                                type="text"
                                value={applePayEmail}
                                error={!!applePayError}
                                helperText={applePayError || " "}
                                onChange={(e) => {
                                  let email = CUSTOM.strFormat(
                                    e.target.value,
                                    "max:50|email"
                                  );
                                  setApplePayEmail(email);
                                  checkApplePayEmail(email);
                                }}
                              />
                            </div>
                          ) : null}
                          <div className="grid grid-cols-2 xs:grid-cols-1 gap-x-5 w-full mb-2">
                            <ApplePayComponent
                              applePayData={applePayDetail}
                              payViaOtherSource={payViaOtherSource}
                              isApplePayEnabled={
                                auth?.email || (applePayEmail && !applePayError)
                              }
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
                {/* <Tab
                  className="common-pointer flex flex-row flex-row gap-3 items-start justify-start w-full pt-5 pb-5"
                  isSelected={activeTab === 2}
                  onClick={() => handleTabClick(2)}
                >
                  <Radio
                    className="flex"
                    name="paymentMode"
                    checked={activeTab === 2}
                    onChange={() => handleTabClick(2)}
                  />
                  <div className="flex flex-col items-start gap-1 justify-start flex-1 -mt-1">
                    <Text className="text-base text-black-900 font-nunitoregular">
                      {t("buyNowPayLater")}
                    </Text>
                  </div>
                </Tab> */}
              </>
            ) : null}
          </div>
        ) : null}

        {orderData?.pageStep > 3 &&
        orderData?.delivery_mode == "buying_for_love" ? (
          <div className="px-6 xs:px-4">
            <div className="common-pointer bg-light_blue-50 flex flex-col items-center justify-start py-3 px-6 xs:px-3 w-full rounded mt-6">
              <div className="flex flex-row items-center justify-between w-full gap-x-10 gap-y-3 xs:flex-col xs:items-start">
                <div className="flex flex-col flex-1 w-full gap-3 items-start justify-start">
                  <Text className="text-black-900 text-sm font-nunitoregular">
                    {t(
                      "thePaymentWillBeRequestedByThePlatformOnceTheRecipientConfirmsTheDetailsYouWillBeNotifiedForTheSame"
                    )}
                  </Text>
                  <Text className="text-black-900 text-sm font-nunitoregular">
                    {t(
                      "onceTheRecipientHasConfirmedTheDetailsYouHave30MinutesToMakePaymentAndConfirmThisOrderOtherwiseItWillBeCancelled"
                    )}
                  </Text>
                </div>
                <Button
                  className="w-auto mx-auto text-base py-[11px] px-[24px] xs:mx-0"
                  size="lg"
                  variant="FillBlack"
                  hover={true}
                  hoverclass="bg-light_blue-50"
                  onClick={(e) => {
                    sendGift();
                  }}
                >
                  {t("sendGift")}
                </Button>
              </div>
            </div>
          </div>
        ) : null}

        <div className="bg-red-50_01 flex md:flex-col flex-row md:gap-5 items-center justify-between py-[22px] px-[30px] xs:p-5 mt-7 w-full">
          <div className="flex flex-1 flex-row gap-3 items-center justify-start w-full">
            <Img
              className="h-9 w-9"
              src="images/img_shielddone.svg"
              alt="shielddone"
            />
            <div className="flex flex-col gap-1 items-start justify-start">
              <Text className="text-base text-black-900 font-nunitomedium">
                {t("secureCardPayment")}
              </Text>
              <Text className="text-black-900 text-xs font-nunitoregular">
                {t("100SecurePaymentsPoweredByRabbitHole")}
              </Text>
            </div>
          </div>
          <div className="gap-2 grid grid-cols-4 w-auto">
            <div className="flex flex-col items-center justify-start w-full">
              <div className="h-8 w-[44px] bg-white-A700 rounded-md">
                <Img
                  className="h-full w-full object-contain"
                  src="images/img_file_white_a700.svg"
                  alt="file"
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-start w-full">
              <div className="h-8 w-[44px] bg-white-A700 rounded-md">
                <Img
                  className="h-full w-full object-contain"
                  src="images/img_023mastercard1.png"
                  alt="023mastercardOne"
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-start w-full">
              <div className="h-8 w-[44px] bg-white-A700 rounded-md">
                <Img
                  className="h-full w-full object-contain"
                  src="images/img_007visa.png"
                  alt="007visa"
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-start w-full">
              <div className="h-8 w-[44px] bg-white-A700 rounded-md">
                <Img
                  className="h-full w-full object-contain"
                  src="images/img_1200pxrupaysvg.png"
                  alt="1200pxrupaysvg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
