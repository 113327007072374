import React, { useEffect, useState } from "react";
import { Button, Img, List } from "components";
import Footer from "components/Footer";
import ProductCartCardBox from "components/ProductCartCardBox";
// Importing localization strings
import { useTranslation } from "react-i18next";
import SearchEmptyPage from "./SearchEmpty";
import { changeLoader } from "../../redux/reducers/loader";
import { useSelector, useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/reducers/snackbar";
import * as CUSTOM from "../../utils/helper/custom";
import globalRequest from "utils/global/globalRequest";
import * as API from "../../utils/helper/Enum";
import { currentTrigger } from "redux/reducers/trigger";
let timer = null;

const SearchPage = () => {
  const { t } = useTranslation();
  let dispatch = useDispatch();
  const loaderStatus = useSelector(currentTrigger);
  let [inputData, setInputData] = useState({ page: 1, limit: 9 });
  const setApiStatus = useState(false)[1];
  const [list, setList] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [isLoadMore, setLoadMore] = useState(false);

  /**
   * const load more api
   */
  const loadMoredata = () => {
    let pageCount = inputData?.page + 1;
    inputData.page = pageCount;
    setLoadMore(false);
    setInputData({ ...inputData, page: pageCount });
    getProducts();
  };

  /**
   * search product
   */
  const getProducts = async () => {
    setApiStatus(true);
    dispatch(changeLoader(true));
    try {
      let response = await globalRequest(
        `${API.PRODUCTS.SEARCH}`,
        "get",
        {},
        { params: inputData },
        true
      );
      response = response?.data;
      if (response?.status == "SUCCESS") {
        setLoadMore(
          response?.data?.data.length < inputData?.limit ? false : true
        );
        if (inputData?.page == 1) {
          setList(response?.data?.data);
        } else {
          setList((prevList) => [...prevList, ...response?.data?.data]);
        }
      } else {
        if (inputData?.page == 1) {
          setLoadMore(false);
          setList([]);
        }
      }
    } catch (e) {
      setList([]);
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }
    setApiStatus(false);
    dispatch(changeLoader(false));
  };

  /**
   * onscroll pagination
   */
  const handleScroll = (e) => {
    const scrollHeight = e.target.documentElement.scrollHeight;
    const currentHeight =
      e.target.documentElement.scrollTop + window.innerHeight + 450;
    if (currentHeight + 1 >= scrollHeight) {
      let clickMore = document.getElementById("loadmoreItem");
      if (clickMore) {
        clickMore.click();
      }
    }
  };

  /**
   * search product
   */
  useEffect(() => {
    clearTimeout(timer);
    setList([]);
    inputData.page = 1;
    setInputData({ ...inputData, page: 1 });
    setLoadMore(false);
    //  if (inputData?.search) {
    //   clearTimeout(timer);
    // setList([]);
    // inputData.page = 1;
    // setInputData({ ...inputData, page: 1 });
    // setLoadMore(false);
    timer = setTimeout(() => {
      if (inputData?.search) {
        getProducts();
      } else {
        setLoadMore(false);
        setList([]);
      }
    }, 500);
    // } else {
    // setLoadMore(false);
    // setList([]);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputData?.search]);

  /**
   * Load card data
   */
  useEffect(() => {
    setCartData(CUSTOM.getCartData());
  }, []);

  /**
   * Load card data
   */
  useEffect(() => {
    if (loaderStatus == "loadCartData") {
      setCartData(CUSTOM.getCartData());
    }
  }, [loaderStatus]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="bg-white-A700 flex flex-col items-center justify-start mx-auto w-full">
        <div className="md:px-4 w-full">
          <div className="bg-gray-50_02 flex items-center px-[20px] py-[12px] mt-10 w-full max-w-[920px] mx-auto rounded">
            <input
              type="text"
              value={inputData?.search}
              placeholder={t("searchForProductsCategoryAndMore")}
              className="border-none bg-transparent p-0 w-full placeholder-gray-700"
              onChange={(e) => {
                setInputData({
                  ...inputData,
                  search: CUSTOM.strFormat(e.target.value, "max:200"),
                });
              }}
            />
            <Img
              className="cursor-pointer h-6 ml-[16px] my-auto"
              src="/images/img_search.svg"
              alt="search"
            />
          </div>
          {list.length == 0 ? (
            <SearchEmptyPage />
          ) : (
            <div className="bg-gray-50_02 flex flex-col items-center justify-start max-w-[920px] mx-auto py-[30px] px-10 md:px-[30px] sm:px-5 w-full sm:mb-[20px] mt-5">
              <List
                className="sm:flex-col flex-row gap-[40px] grid xs:grid-cols-1 sm:grid-cols-2 grid-cols-3 justify-center mx-auto w-full mb-8"
                orientation="horizontal"
              >
                {list.map((pitems, index) => {
                  return (
                    <ProductCartCardBox
                      key={`pitem-${pitems?.id}`}
                      productId={pitems?.id}
                      className="bg-white-A700 flex flex-1 flex-col items-center justify-start p-[16px] rounded-lg w-full"
                      productimage={CUSTOM.getImage(
                        pitems?.productUoms?.[0]?.productUomImages?.[0]
                          ?.image || ""
                      )}
                      isRecommended={pitems?.isRecommended}
                      isBestSeller={pitems?.isBestseller}
                      productRatingBox={!!pitems?.averageRating}
                      productrating={`${
                        pitems?.averageRating ? pitems?.averageRating : 0
                      } | ${pitems?.ratingCount ? pitems?.ratingCount : 0}`}
                      productName={`${CUSTOM?.getdataByLangKey(
                        pitems?.productLocales,
                        "",
                        "name"
                      )} - ${pitems?.productUoms?.[0]?.name || ""}`}
                      discountedPrice={
                        <>
                          {t("sar")}{" "}
                          {pitems?.productUoms?.[0]?.sellingPrice || ""}
                        </>
                      }
                      originalPrice={
                        !pitems?.productUoms?.[0]?.actualPrice ||
                        pitems?.productUoms?.[0]?.sellingPrice ==
                          pitems?.productUoms?.[0]?.actualPrice
                          ? ""
                          : pitems?.productUoms?.[0]?.actualPrice
                      }
                      buttonAddToCart
                      slug={pitems?.slug}
                      uomData={pitems?.productUoms?.[0]}
                      cartData={cartData}
                    />
                  );
                })}
              </List>

              {isLoadMore ? (
                <Button
                  id="loadmoreItem"
                  className="hide"
                  onClick={(e) => {
                    loadMoredata();
                  }}
                >
                  Load More
                </Button>
              ) : null}

              {/* {apiStatus ? (
                <div className="flex h-12 items-center justify-center mt-4 mb-10 rounded-[50%] w-full">
                  <Img
                    src="/images/Loaderanim.png"
                    className="animate-spin"
                    alt="loaderanim"
                  />
                </div>
              ) : null} */}
            </div>
          )}
        </div>
        <Footer className="flex font-nunitoregular items-center justify-center mt-[127px] md:px-5 w-full" />
      </div>
    </>
  );
};

export default SearchPage;
