/**
 * credit or dabit cart validation
 */
export const validateCard = (card_number) => {
  var card_type = "";
  var card_validity = true;
  var message = '';
  var card_length = 0;
  const aps_info = {
    mada_bins: "some_mada_bins_regex",
    meeza_bins: "some_meeza_bins_regex",
    have_recurring_items: false,
    error_msg: {
      invalid_card: "Invalid card number.",
      invalid_card_length: "Invalid card length.",
      card_empty: "Card number is empty."
    }
  };

  if (card_number) {
    card_number = card_number.replace(/ /g, '').replace(/-/g, '');
    var visa_regex = new RegExp('^4[0-9]{0,15}$');
    var mastercard_regex = new RegExp('^5$|^5[0-5][0-9]{0,16}$');
    var amex_regex = new RegExp('^3$|^3[47][0-9]{0,13}$');
    var mada_regex = new RegExp('^' + aps_info.mada_bins + '', 'm');
    var meeza_regex = new RegExp(aps_info.meeza_bins, 'gm');

    if (card_number.match(mada_regex)) {
      if (aps_info.have_recurring_items) {
        card_validity = false;
        message = aps_info.error_msg.invalid_card;
      } else {
        card_type = 'mada';
        card_length = 16;
      }
    } else if (card_number.match(meeza_regex)) {
      if (aps_info.have_recurring_items) {
        card_validity = false;
        message = aps_info.error_msg.invalid_card;
      } else {
        card_type = 'meeza';
        card_length = 19;
      }
    } else if (card_number.match(visa_regex)) {
      card_type = 'visa';
      card_length = 16;
    } else if (card_number.match(mastercard_regex)) {
      card_type = 'mastercard';
      card_length = 16;
    } else if (card_number.match(amex_regex)) {
      card_type = 'amex';
      card_length = 15;
    } else {
      card_validity = false;
      message = aps_info.error_msg.invalid_card;
    }

    if (card_number.length < 15) {
      card_validity = false;
      message = aps_info.error_msg.invalid_card_length;
    }
  } else {
    message = aps_info.error_msg.card_empty;
    card_validity = false;
  }
  return {
    card_type,
    validity: card_validity,
    msg: message,
    card_length,
  };
}

/**
 * cart holder name validation
 */
export const validateHolderName = (card_holder_name) => {
  var validity = true;
  var message = '';
  card_holder_name = card_holder_name.trim();
  if (card_holder_name.length > 255 || card_holder_name.length == 0) {
    validity = false;
    message = "";
  }
  return {
    validity,
    msg: message
  }
}

/**
 * check cart validity month & year
 */

export const validateExpiryDate = (expiry) => {
  var regex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
  if (!regex.test(expiry)) {
    return false;
  }

  var today = new Date();
  var currentMonth = today.getMonth() + 1; // Adding 1 because getMonth() returns 0-11 for the months.
  var currentYear = today.getFullYear() % 100; // Extracting last two digits of the year.

  var inputMonth = parseInt(expiry.substring(0, 2));
  var inputYear = parseInt(expiry.substring(3));

  if (inputYear < currentYear || (inputYear === currentYear && inputMonth < currentMonth)) {
    return false; // Expiry date is in the past.
  }

  return true;
}