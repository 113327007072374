import { Button, Img, Text } from "components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ViewImage } from "popups/ViewImage";
import * as CUSTOM from "../../../../utils/helper/custom";
import { useNavigate } from "react-router-dom";

const OrderDetailCustomCake = ({ orderData, setIsGiftPayment }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [ViewImageModalOpen, setViewImageModalOpen] = React.useState(false);
  const [prepTime, setPrepTime] = useState(0);
  const [allImages, setAllImages] = useState([]);

  const handelsetViewImageModalOpen = (imgArray) => {
    setAllImages(imgArray);
    setViewImageModalOpen(true);
    document.body.classList.add("overflow-hidden");
    document.getElementById("header-box").classList.add("relative");
  };

  const getGroupItemName = (items) => {
    return items
      .map((item) =>
        CUSTOM.getdataByLangKey(item?.modifierGroupItemLocales, "", "name")
      )
      .join(", ");
  };

  useEffect(() => {
    let totalTime = 0;
    orderData?.orderDetails?.forEach((oitem) => {
      oitem?.orderItemDetails?.cakeDetail?.forEach((orderIt) => {
        orderIt?.modifierGroupItems?.forEach((nitem) => {
          totalTime += nitem?.prepTime;
        });
      });
    });
    setPrepTime(CUSTOM.minutsToHour(totalTime));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {orderData?.status == "pending" ? (
        <div className="bg-light_blue-50 flex flex-row justify-between items-center mt-6 p-5 rounded w-full">
          <div className="flex flex-row gap-3 justify-start pr-5">
            <Img
              src="/images/img_info-black.svg"
              className="w-6 h-6"
              alt="info"
            />
            <div className="relative">
              <Text className="font-nunitoregular text-sm flex text-black-900 leading-[24px]">
                {t(
                  "yourCustomCakeRequestHasBeenSentToTheAdminForTheApproval_text"
                )}
              </Text>
            </div>
          </div>
        </div>
      ) : null}

      {orderData?.status == "approved" ? (
        <div className="bg-light_blue-50 flex flex-row justify-between items-center mt-6 p-5 rounded w-full">
          <div className="flex flex-row gap-3 justify-start pr-5">
            <Img
              src="/images/img_info-black.svg"
              className="w-6 h-6"
              alt="info"
            />
            <div className="relative">
              <Text className="font-nunitoregular text-sm flex text-black-900 leading-[24px]">
                {t("customCakeApproved")}
              </Text>
            </div>
          </div>
          <Button variant={"FillBlack"} className="min-w-[168px]" size="sm">
            <Text
              className="mx-3 font-nunitomedium text-white whitespace-nowrap"
              as="span"
              onClick={(e) => {
                navigate(
                  `/custom-cake-order/${CUSTOM.base64Encode(orderData?.id)}`
                );
              }}
            >
              {t("placeOrder2")}
            </Text>
          </Button>
        </div>
      ) : null}

      <div className="bg-red-50 flex flex-col items-center justify-start mt-5 w-full rounded relative">
        <div
          className="bg-left-top bg-no-repeat flex flex-col items-center justify-start px-5 pt-5 pb-8 w-full min-h-[440px]"
          style={{ backgroundImage: "url('images/custom-cake-summary.svg')" }}
        >
          <div className="flex flex-row xs:flex-col xs:items-start gap-3 items-center justify-between w-full mb-3">
            <Text className="font-nunitomedium text-base ">
              {t("customCakeSummary")}
            </Text>
            <Button
              className="flex items-center bg-red-50_01 justify-center  px-2 py-[7px] rounded"
              leftIcon={
                <Img
                  className="h-[18px] mr-2 rtl:mr-0 rtl:ml-2"
                  src="/images/img_clock.svg"
                  alt="clock"
                />
              }
            >
              <div className="text-left text-gray-900_01 font-nunitomedium text-sm rtl:text-right">
                {t("estimatedPrepTime")} {prepTime} hrs
              </div>
            </Button>
          </div>
          {orderData?.orderDetails.map((oitem, index) => (
            <div
              className="flex flex-col items-start justify-between w-full mb-6"
              key={`outer-${index}`}
            >
              {oitem?.orderItemDetails?.cakeDetail?.map((orderIt, oindex) => (
                <div className="mb-6" key={`inner-${oindex}`}>
                  {/* Repeated elements extracted outside the inner mapping */}
                  <Text className="font-nunitomedium text-base mb-1">
                    {CUSTOM.getdataByLangKey(
                      orderIt?.modifierGroupLocales,
                      "",
                      "name"
                    )}
                  </Text>
                  <Text className="font-nunitoregular text-sm text-black-900 leading-[24px]">
                    {getGroupItemName(orderIt?.modifierGroupItems)}
                  </Text>
                </div>
              ))}

              <div className="mb-6" key={`inner-23122413243`}>
                {/* Repeated elements extracted outside the inner mapping */}
                <Text className="font-nunitomedium text-base mb-1">
                  {t("referenceImageSlashLink")}
                </Text>
                <Text className="font-nunitoregular text-sm text-black-900 leading-[24px]">
                  <div className="flex flex-wrap flex-row gap-3 items-start justify-start w-full">
                    {oitem?.orderItemDetails?.imageLinks?.map(
                      (itemsLinks, ind) => {
                        return (
                          <Img
                            className="cursor-pointer h-[64px] w-[64px] object-cover rounded-lg"
                            src={itemsLinks}
                            alt="rectangle19120"
                            loading="lazy"
                            onClick={() =>
                              handelsetViewImageModalOpen(
                                oitem?.orderItemDetails?.imageLinks
                              )
                            }
                          />
                        );
                      }
                    )}
                  </div>
                  {oitem?.orderItemDetails?.referenceLink ? (
                    <Text className="font-nunitoregular text-sm text-black-900 leading-[24px] underline">
                      <a
                        href={
                          oitem?.orderItemDetails?.referenceLink.startsWith(
                            "http"
                          )
                            ? oitem?.orderItemDetails?.referenceLink
                            : `http://${oitem?.orderItemDetails?.referenceLink}`
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {oitem?.orderItemDetails?.referenceLink}
                      </a>
                    </Text>
                  ) : null}
                </Text>
              </div>

              {oitem?.orderItemDetails?.textOnCake ? (
                <div className="mb-6" key={`inner-23122413243`}>
                  {/* Repeated elements extracted outside the inner mapping */}
                  <Text className="font-nunitomedium text-base mb-1">
                    {t("nameoncake")}
                  </Text>
                  <Text className="font-nunitoregular text-sm text-black-900 leading-[24px]">
                    {oitem?.orderItemDetails?.textOnCake ? (
                      <Text className="font-nunitoregular text-sm text-black-900 leading-[24px]">
                        {oitem?.orderItemDetails?.textOnCake}
                      </Text>
                    ) : null}
                  </Text>
                </div>
              ) : null}
            </div>
          ))}
        </div>
        <Img
          className="h-[356px] mr-2 rtl:mr-0 rtl:ml-2 absolute bottom-8 ltr:-right-6 rtl:-left-6 xs:hidden"
          src="/images/customCakeSummaryImg.svg"
          alt="cake"
        />
      </div>

      {ViewImageModalOpen === true ? (
        <ViewImage closepopup={setViewImageModalOpen} images={allImages} />
      ) : null}
    </>
  );
};

export default OrderDetailCustomCake;
