import { Button, Img } from "components";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useRef } from "react";
import globalRequest from "../../utils/global/globalRequest";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../redux/reducers/loader";
import * as API from "../../utils/helper/Enum";
import { setSnackbar } from "../../redux/reducers/snackbar";

const ImageCropper = ({
  setImageForCrop,
  image,
  allSelectedImages,
  setAllSelectedImages,
}) => {
  const cropperRef = useRef(null);
  const dispatch = useDispatch();
  const closepopupout = () => {
    setImageForCrop("");
  };

  /**
   *  Get cropped image
   */
  const getCroppedImage = async () => {
    if (typeof cropperRef.current.getCroppedCanvas() === "undefined") {
      return;
    }
    const croppedImage = cropperRef.current.getCroppedCanvas().toDataURL(); 
    try {
      dispatch(changeLoader(true));
      let response = await globalRequest(
        `${API.CUSTOMCAKE.UPLOAD_IMAGE}`,
        "post",
        {image:croppedImage},
        {},
        true
      );
      response = response?.data;
      dispatch(changeLoader(false));
      if (response?.status == "SUCCESS") {
        const updatedImages = [...allSelectedImages, response?.data];
        setAllSelectedImages(updatedImages);
        setImageForCrop("");
      } else {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarMessage: response?.message,
            snackbarState: "error",
          })
        );
      }
    } catch (e) {
      dispatch(
        setSnackbar({
          snackbarOpen: true,
          snackbarMessage: e?.message,
          snackbarState: "error",
        })
      );
    }  
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none m-auto w-fit h-fit xs:px-4">
        <div className="relative w-auto my-6 mx-auto max-w-[500px] min-w-[500px] xs:min-w-full xs:max-w-full">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white-A700 outline-none focus:outline-none overflow-hidden">
            {/*header*/}
            <div className="flex flex-col items-center justify-start w-auto">
              <div className="flex flex-col items-center justify-start w-auto">
                <div className="flex flex-row items-start justify-end w-auto pt-1 pr-1 rtl:pr-0 rtl:pl-1 absolute right-0 rtl:right-auto rtl:left-0 z-[10]">
                  <Img
                    src="/images/modal-close.svg"
                    className="common-pointer h-7 w-7 rounded-[50%] bg-red-50_01 p-1 object-contain"
                    alt="close"
                    onClick={() => closepopupout()}
                  />
                </div>
              </div>
            </div>
            {/*body*/}
            <div className="bg-white-A700 flex flex-col items-center justify-start px-[12px] pt-3 pb-8 rounded-lg w-full">
              <div className="flex flex-col justify-start w-full relative z-[5]">
                <div>
                  {image && (
                    <div style={{ width: "100%", height: 400 }}>
                      <Cropper
                        style={{ height: 360, width: "100%" }}
                        initialAspectRatio={4 / 3}
                        aspectRatio={4 / 3}
                        preview=".img-preview"
                        src={image}
                        strict={true}
                        viewMode={1}
                        guides={true}
                        zoomable={true}
                        zoomOnWheel={true}
                        minCropBoxHeight={100}
                        maxWidth="10"
                        minCropBoxWidth={100}
                        maxCropBoxHeight={300} // set your preferred maximum height here
                        maxCropBoxWidth={300} // set your preferred maximum width here
                        background={false}
                        responsive={true}
                        autoCropArea={0}
                        checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                        onInitialized={(instance) => {
                          cropperRef.current = instance;
                        }}
                      />
                      <div className="flex justify-end">
                      <Button
                      className="rounded"
                        variant="FillBlack"
                        size = "smIcn"
                        color="primary"
                        onClick={getCroppedImage}
                        style={{ marginTop: "10px", color: "white"}}
                      >
                        GET CROPPED IMAGE
                      </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-75 fixed inset-0 z-40 bg-black-900"
        onClick={() => closepopupout()}
      ></div>
    </>
  );
};
export { ImageCropper };
