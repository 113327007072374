import { createSlice } from "@reduxjs/toolkit";

export const trigger = createSlice({
  name: "trigger",
  initialState: {
    value: false,
  },
  reducers: {
    changeTrigger: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeTrigger } = trigger.actions;
export const currentTrigger = (state) => state.trigger.value;
export default trigger.reducer;
