import { useState } from "react";
import { LoadScript } from "@react-google-maps/api";
import MyMapComponent from "./MyMapComponent";
const lib = ["places"];
const key = process.env.REACT_APP_GOOGLE_API_KEY; // PUT GMAP API KEY HERE

const AutoFillAddress = (props) => {
  const [isLoad,setIsLoaded]=useState(false);

  const handleLoadScript = () => {
    setIsLoaded(true);
  };

  return (
    <LoadScript
      googleMapsApiKey={key}
      libraries={lib}
      onLoad={handleLoadScript}
    >
      {isLoad?(<MyMapComponent />):<>Loading...</>}
    </LoadScript>
  );
};

export { AutoFillAddress };
