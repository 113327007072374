import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Img, Text, Radio } from "components";
import { useTranslation } from "react-i18next";
import SavedAddressesList from "./SavedAddressesList";
import PickUpPointsList from "./PickUpPointsList";
import SendAsAGift from "./SendAsAGift";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getOrderData, updateOrderData } from "redux/reducers/orderData";
import { AddEditAddress } from "popups/AddEditAddress";
import { getAuth } from "redux/reducers/loginData";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const Tab = styled.div`
  /* Add the selected styles when the component is selected */
  ${({ isSelected }) =>
    isSelected &&
    ` 
  border-color: #BD0043 !important;
`}
`;
const Panal = styled.div`
  /* Add the selected styles when the component is selected */
  ${({ isSelected }) =>
    isSelected &&
    ` 
  display: block !important;
`}
`;

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 300,
    margin: 0,
    padding: 0,
    ".MuiTooltip-arrow": {
      color: "#FFFFFF", // Arrow color
      width: "28px", // Arrow width
      height: "16px", // Arrow height
      bottom: "-8px", // Position where arrow is pointing
      left: "-9px !important", // Position where arrow is pointing
    },
  },
}));

const DeliveryMode = (props) => {
  const {
    addressList,
    zoneList,
    occasionOptions,
    isEventSent,
    setIsEventSent,
    sendCheckoutGaEvent,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector(getAuth);
  const orderData = useSelector(getOrderData);
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();
  const [AddAddressPopup, setAddAddressPopup] = useState(false);

  const handleTabClick = (index) => {
    if (!isEventSent?.beginCheckout) {
      setIsEventSent((prev) => ({ ...prev, beginCheckout: true }));
      sendCheckoutGaEvent("begin_checkout");
    }
    dispatch(
      updateOrderData({
        ...orderData,
        DeliveryModeActiveTab: index,
        delivery_mode: "",
        delivery_type: "",
        deliveryDateTimeActiveTab: 0,
      })
    );
  };

  useEffect(() => {
    setActiveTab(
      orderData?.DeliveryModeActiveTab ? orderData?.DeliveryModeActiveTab : null
    );
  }, [orderData?.DeliveryModeActiveTab]);

  // useEffect(() => {
  //   if (orderData?.addAddressPopup) {
  //     setAddAddressPopup(true);
  //   }
  // }, [orderData?.addAddressPopup]);

  // useEffect(() => {
  //   if (AddAddressPopup == "getAddressList") {
  //     setAddAddressPopup(false);
  //     dispatch(changeTrigger("loadAddressList"));
  //   } else {
  //     if (!AddAddressPopup) {
  //       dispatch(updateOrderData({ ...orderData, addAddressPopup: false }));
  //     }
  //   }
  // }, [AddAddressPopup]);

  return (
    <>
      <div className="relative flex flex-col w-full">
        {/* After fill Delivery Mode */}
        {orderData?.pageStep > 2 ? (
          <>
            <div className="flex flex-col gap-4 items-center justify-start w-full mb-2">
              <div className="flex flex-row xs:flex-col md:gap-5 items-center xs:items-start justify-start w-full">
                <div className="flex flex-col gap-1 items-start justify-start flex-1">
                  <Text className="text-base text-black-900 font-nunitomedium">
                    {t("deliveryMode")}:{" "}
                    {(() => {
                      switch (orderData?.delivery_mode) {
                        case "buying_for_love":
                          return t("buyingForALovedOne");
                        case "pickup_point":
                          return t("PickUpPoint");
                        case "home_office":
                          return t("Home");
                        default:
                          return "";
                      }
                    })()}
                  </Text>

                  {orderData?.delivery_mode === "buying_for_love" && (
                    <>
                      <div className="flex gap-1 flex-row items-center">
                        <Text className="text-black-900 text-sm font-nunitoregular">
                          {orderData?.buying_for_love?.recipient_name}
                        </Text>
                      </div>
                      <div className="flex gap-1 flex-row items-center">
                        <Text className="text-black-900 text-sm nunitoregular">
                          {orderData?.buying_for_love?.country_code}{" "}
                          {orderData?.buying_for_love?.mobile_no} |{" "}
                          {orderData?.buying_for_love?.email}
                        </Text>
                      </div>
                    </>
                  )}

                  {orderData?.delivery_mode !== "buying_for_love" && (
                    <>
                      <div className="flex gap-1 flex-row items-center">
                        <Text className="text-black-900 text-sm nunitoregular">
                          {orderData?.delivery_mode === "home_office" ||
                          orderData?.delivery_mode === "pickup_point"
                            ? orderData?.home_office?.address
                            : null}
                        </Text>
                      </div>
                    </>
                  )}
                </div>
                <Button
                  className="w-auto mx-auto px-3 py-1.5 text-sm sm:mx-0"
                  size="sm"
                  variant="OutlineBlack"
                  hover={true}
                  hoverclass="bg-black-900"
                  onClick={(e) => {
                    dispatch(
                      updateOrderData({
                        ...orderData,
                        pageStep: 2,
                        delivery_mode: "",
                        delivery_type: "",
                      })
                    );
                  }}
                >
                  {t("change")}
                </Button>
              </div>
            </div>
          </>
        ) : null}

        {/* After fill Delivery Mode */}
        {!orderData?.delivery_mode || orderData?.pageStep <= 2 ? (
          <div className="flex flex-col gap-4 items-center justify-start w-full mt-0.5 mb-2">
            <div className="flex flex-row md:gap-5 items-center justify-start w-full">
              <div className="flex flex-col gap-1 items-start justify-start flex-1">
                <Text className="text-base text-black-900 font-nunitomedium">
                  {t("deliveryMode")}
                </Text>
                <div className="flex gap-1 flex-row items-center">
                  <Text className="text-black-900 text-sm font-nunitoregular">
                    {t("forYourItemsChooseTheDeliveryMode")}
                  </Text>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {orderData?.pageStep == 2 ? (
          <div className="flex flex-row md:flex-col flex-wrap w-full gap-x-3 md:gap-y-3 mt-[22px] mb-[18px]">
            <Tab
              className="common-pointer bg-white-A700 border border-gray-300 border-solid flex flex-col gap-4 justify-start p-4 w-[32%] md:w-full md:order-1"
              isSelected={activeTab === 1}
              onClick={() => handleTabClick(1)}
            >
              <div className="flex gap-2">
                <Radio
                  name="deliveryMode"
                  checked={activeTab === 1}
                  onChange={() => handleTabClick(1)}
                />
                <Text className="h-max leading-5 my-auto text-black-900 text-sm w-full font-nunitomedium pr-10 rtl:pr-0 rtl:pl-10">
                  {t("homeOfficeOtherDelivery")}
                </Text>
              </div>
              <Text className="leading-4 text-gray-700 text-xs w-full font-nunitoregular">
                {t("multipleAddressesInThisLocationOrAddANewOne")}
              </Text>
            </Tab>
            <Tab
              className="common-pointer relative bg-white-A700 border border-gray-300 border-solid flex flex-col gap-4 justify-start p-4 w-[32%] md:w-full md:order-3"
              isSelected={activeTab === 2}
              onClick={() => handleTabClick(2)}
            >
              <div className="absolute flex flex-col items-end justify-start -right-2 rtl:right-auto rtl:-left-2 top-[6%] w-[39%]">
                <div
                  className="bg-cover bg-no-repeat flex flex-col h-6 items-end justify-start p-1 w-auto pl-4 rtl:rotate-180"
                  style={{
                    backgroundImage: "url('images/img_group425.svg')",
                  }}
                >
                  <Text className="mr-[7px] text-white-A700 text-xs font-nunitoregular whitespace-nowrap rtl:rotate-180">
                    {t("bestOffer")}
                  </Text>
                </div>
                <Img
                  className="h-2 w-2 rtl:rotate-90"
                  src="images/img_signal_pink_900.svg"
                  alt="signal"
                />
              </div>
              <div className="flex gap-2">
                <Radio
                  name="deliveryMode"
                  checked={activeTab === 2}
                  onChange={() => handleTabClick(2)}
                />
                <Text className="h-max leading-5 my-auto text-black-900 text-sm w-full font-nunitomedium pr-10 rtl:pr-0 rtl:pl-10">
                  {t("pickUp")} <br className="md:hidden" />
                  {t("point")}
                </Text>
              </div>
              <Text className="leading-4 text-gray-700 text-xs w-full font-nunitoregular">
                {t(
                  "getDiscountOnYourOrderByPickingANearestDropZoneAsDeliveryLocation"
                )}
              </Text>
            </Tab>

            {auth?.id == 0 ? (
              <HtmlTooltip
                placement="top-start"
                arrow
                title={
                  <>
                    <div className="bg-white-A700 py-3 px-5 rounded w-full shadow-2xl -ml-5">
                      <Text className="leading-5 text-black-900 text-sm w-full font-nunitomedium">
                        {t("loginNowNeed")}
                        <Text
                          className="text-pink-800 inline ml-1 cursor-pointer"
                          onClick={(e) => {
                            navigate("/login");
                          }}
                        >
                          {t("loginNow")}
                        </Text>
                      </Text>
                    </div>
                  </>
                }
              >
                <Tab
                  className="common-pointer bg-light_blue-50 flex flex-col gap-4 justify-start p-4 bg-no-repeat ltr:bg-right-top rtl:bg-left-top bg-auto w-[32%] border border-solid border-light_blue-50 md:w-full md:order-5"
                  isSelected={activeTab === 3}
                  style={{
                    backgroundImage: "url('images/img_group710.svg')",
                  }}
                >
                  <div className="flex gap-2">
                    <Radio
                      name="deliveryMode"
                      checked={activeTab === 3}
                      onClick={(e) => {
                        if (auth?.id != 0) {
                          //handleTabClick(2);
                        }
                      }}
                    />
                    <Text className="h-max leading-5 my-auto text-black-900 text-sm w-full font-nunitomedium pr-10 rtl:pr-0 rtl:pl-10">
                      {t("buyingForALovedOne")}
                    </Text>
                  </div>
                  <Text className="leading-4 text-gray-700 text-xs w-full font-nunitoregular">
                    {t("sendAGiftAndPersonalizedMessageOrSong")}
                  </Text>
                </Tab>
              </HtmlTooltip>
            ) : (
              <>
                <Tab
                  className="common-pointer bg-light_blue-50 flex flex-col gap-4 justify-start p-4 bg-no-repeat ltr:bg-right-top rtl:bg-left-top bg-auto w-[32%] border border-solid border-light_blue-50 md:w-full md:order-5"
                  isSelected={activeTab === 3}
                  onClick={(e) => {
                    if (auth?.id != 0) {
                      handleTabClick(3);
                    } else {
                    }
                  }}
                  style={{
                    backgroundImage: "url('images/img_group710.svg')",
                  }}
                >
                  <div className="flex gap-2">
                    <Radio
                      name="deliveryMode"
                      checked={activeTab === 3}
                      onClick={(e) => {
                        if (auth?.id != 0) {
                          handleTabClick(3);
                        }
                      }}
                    />
                    <Text className="h-max leading-5 my-auto text-black-900 text-sm w-full font-nunitomedium pr-10 rtl:pr-0 rtl:pl-10">
                      {t("buyingForALovedOne")}
                    </Text>
                  </div>
                  <Text className="leading-4 text-gray-700 text-xs w-full font-nunitoregular">
                    {t("sendAGiftAndPersonalizedMessageOrSong")}
                  </Text>
                </Tab>
              </>
            )}
            <Panal
              isSelected={activeTab === 1}
              className="hidden md:order-2 w-full"
            >
              <SavedAddressesList
                addressList={addressList}
                setAddAddressPopup={setAddAddressPopup}
                isEventSent={isEventSent}
                setIsEventSent={setIsEventSent}
                sendCheckoutGaEvent={sendCheckoutGaEvent}
              />
            </Panal>
            <Panal
              isSelected={activeTab === 2}
              className="hidden md:order-4 w-full"
            >
              <PickUpPointsList
                zoneList={zoneList}
                isEventSent={isEventSent}
                setIsEventSent={setIsEventSent}
                sendCheckoutGaEvent={sendCheckoutGaEvent}
              />
            </Panal>
            <Panal
              isSelected={activeTab === 3}
              className="hidden md:order-6 w-full"
            >
              <SendAsAGift
                occasionOptions={occasionOptions}
                isEventSent={isEventSent}
                setIsEventSent={setIsEventSent}
                sendCheckoutGaEvent={sendCheckoutGaEvent}
              />
            </Panal>

            {AddAddressPopup ? (
              <AddEditAddress
                setAddAddressPopup={setAddAddressPopup}
                orderData={orderData}
                pageName={"cart"}
              ></AddEditAddress>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DeliveryMode;
